import { GET_LEVELS } from './constants';

const initialState = {
  activeLevels: []
};

export default function levels(state = initialState, { type, payload }) {
  switch (type) {
    case GET_LEVELS:
      return {
        ...state,
        activeLevels: payload
      };
    default:
      return state;
  }
}
