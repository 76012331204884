import api from 'utils/api';
import { GET_INVESTMENTS, GET_PLUS_INVESTMENTS, GET_TOPUP_LOGS, GET_USERS_TOTAL_INVESTMENTS, GET_ZERO_INVESTMENTS } from './constants';

export const getInvestment = (body) => async (dispatch) => {
  try {
    const { data } = await api.post('/get_investment', body);
    dispatch({
      type: GET_INVESTMENTS,
      payload: data
    });
  } catch (error) {
    console.log(error);
  }
};

export const getZeroInvestment = () => async (dispatch) => {
  try {
    const { data } = await api.post('/get_zero_investment');
    console.log('data', data);
    dispatch({
      type: GET_ZERO_INVESTMENTS,
      payload: data
    });
  } catch (error) {
    console.log(error);
  }
};
export const getUsersTotalInvestment = (body) => async (dispatch) => {
  try {
    const { data } = await api.post('/get_users_total_investment', body);
    console.log('data', data);
    dispatch({
      type: GET_USERS_TOTAL_INVESTMENTS,
      payload: data
    });
  } catch (error) {
    console.log(error);
  }
};
export const getPlusInvestment = (body) => async (dispatch) => {
  try {
    const { data } = await api.post('/get_plus_investment', body);
    dispatch({
      type: GET_PLUS_INVESTMENTS,
      payload: data
    });
  } catch (error) {
    console.log(error);
  }
};
export const approveInvestment = (body) => async (dispatch) => {
  try {
    const { data } = await api.post('/approve_investment', body);
    dispatch(getInvestment({ chrStatus: 'F' }));
  } catch (error) {
    console.log(error);
  }
};

export const getTopupLogs = (body) => async (dispatch) => {
  try {
    const { data } = await api.post('/get_list_topup_logs', body);
    dispatch({
      type: GET_TOPUP_LOGS,
      payload: data
    });
  } catch (error) {
    console.log(error);
  }
};
