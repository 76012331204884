import { Card, Grid, Typography } from '@mui/material';
import React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMileStones } from 'store/milestone/actions';
import ReactApexChart from 'react-apexcharts';
import api from 'utils/api';

const MileStoneDonutChart = ({ userId }) => {
  const dispatch = useDispatch();
  const series1 = ['12', '23', '21'];
  const [options] = useState({
    chart: {
      height: 390,
      type: 'radialBar'
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 15,
          size: '60%',
          // image: 'https://www.pikpng.com/pngl/b/365-3652238_53-female-46-male-gender-icon-gif-png.png',
          imageWidth: 120,
          imageHeight: 120,
          imageClipped: false
        },
        dataLabels: {
          name: {
            show: false,
            color: 'black'
          },
          value: {
            show: true,
            color: '#333',
            offsetY: 70,
            fontSize: '22px'
          }
        }
      }
    },
    colors: ['#1699D7', '#df78eb', '#e2dee3'],
    stroke: {
      lineCap: 'round'
    },
    labels: ['Male', 'Female', 'Other']
  });
  const [mileStonereached, setMileStonereached] = useState(0);
  const { walletDetails } = useSelector((state) => state?.wallet);
  const allMilestones = walletDetails?.arrMiles;
  const currentMilestone = allMilestones?.find((milestone) => milestone.isCurrent);
  const [maxValue, setMaxValue] = useState(1000);
  const [userRefCode, setUserRefCode] = useState('');
  const [directUsers, setDirectUsers] = useState([]);
  // useEffect(() => {
  //   if (currentMilestone?.intAmount) {
  //     setMaxValue(currentMilestone?.intAmount);
  //   }
  // }, [currentMilestone]);
  const [pointsArray, setPointsArray] = useState([]);
  useEffect(() => {
    dispatch(getMileStones());
  }, []);
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userData = await api.post('/get_user_by_id', { strUserId: userId });
        setUserRefCode(userData?.data?.strRefCode);
      } catch (error) {
        console.log(error);
      }
    };
    fetchUserData();
  }, [userId]);
  const { mileStoneList } = useSelector((state) => state?.mileStone);
  useEffect(() => {
    // Simulate initial API call for the root users
    async function fetchRootUsers() {
      if (!userRefCode) {
        return;
      }
      try {
        const response = await api.post('/get_ref_legs', {
          strRefCode: userRefCode
        });

        if (response.data.success) {
          setDirectUsers(response.data.arrList);
        }
      } catch (error) {
        console.error('Error fetching root users:', error);
      }
    }

    fetchRootUsers();
  }, [userRefCode]);
  useEffect(() => {
    let totalCapitalAmount = 0;

    directUsers.forEach((user) => {
      let userTotal = user.arrInvestMents.reduce((acc, investment) => {
        return acc + investment.intCapitalAmt;
      }, 0);
      // Add individual user's intCapitalAmt to the total
      totalCapitalAmount += userTotal;
    });

    console.log('Total intCapitalAmt of all users:', totalCapitalAmount);
    setMileStonereached(totalCapitalAmount);
  }, [directUsers]);
  useEffect(() => {
    let points = [];
    if (mileStoneList?.length > 0) {
      let maximumValue = mileStoneList[0]?.intAmount;
      for (let i = 0; i < mileStoneList?.length; i++) {
        // points.push({
        //   value: mileStoneList[i].intAmount,
        //   label: `${mileStoneList[i].intAmount}$`
        // });
        // if (i > 0 && mileStoneList[i]?.intAmount > maximumValue) {
        //   maximumValue = mileStoneList[i]?.intAmount;
        // }
        if (mileStonereached < mileStoneList[i].intAmount) {
          maximumValue = mileStoneList[i].intAmount;
          break;
        }
      }
      setMaxValue(maximumValue);
    } else {
      points.push({ value: 0, label: 'No milestones available' });
    }
    setPointsArray(points);
  }, [mileStoneList, mileStonereached]);

  //   const chartOptions = {
  //     dataLabels: {
  //       enabled: true,
  //       formatter: function (val) {
  //         return '$' + val;
  //       }
  //     },
  //     labels: pointsArray.map((point) => point.label),
  //     plotOptions: {
  //       pie: {
  //         donut: {
  //           size: '65%'
  //         }
  //       }
  //     },
  //     colors: ['#ff5722', '#2196f3', '#f44336', '#4caf50'] // Customize the colors as needed
  //   };

  //   const chartSeries = [mileStonereached, maxValue - mileStonereached];
  const chartOptions = {
    chart: {
      type: 'donut'
    },
    plotOptions: {
      pie: {
        donut: {
          size: '70%', // Adjust the size of the donut hole as needed
          labels: {
            show: true,
            name: {
              fontSize: '22px'
            },
            value: {
              fontSize: '16px'
            },
            total: {
              show: true,
              label: 'Total',
              fontSize: '16px'
            }
          },
          stroke: {
            colors: ['#000']
          }
        }
      }
    }
  };

  const chartSeries = [44, 55, 13, 43, 22];
  return (
    <Card sx={{ backgroundColor: '#051114', padding: '16px' }}>
      <Grid container>
        <Grid item xs={12} md={12}>
          <Typography sx={{ color: 'white' }} variant="h3">
            Salary Milestone
          </Typography>
        </Grid>
        <Grid item xs={12} md={12}>
          <Typography sx={{ color: 'white' }} variant="h4">
            ${mileStonereached ? mileStonereached : 0}
          </Typography>
        </Grid>
        <Grid item xs={12} md={12} display={'flex'} justifyContent={'center'}>
          <div id="chart">
            <ReactApexChart
              type="donut"
              // width={400}
              // height={400}
              series={[mileStonereached, maxValue - mileStonereached]}
              options={{
                labels: ['Achieved', 'Balance'],

                responsive: [
                  {
                    breakpoint: 480,
                    options: {
                      chart: {
                        width: 275
                      },
                      legend: {
                        position: 'bottom'
                      }
                    }
                  }
                ],
                plotOptions: {
                  pie: {
                    donut: {
                      labels: {
                        show: true,
                        total: {
                          show: true,
                          showAlways: true,
                          label: 'Salary Ratio',
                          // formatter: (value) => {
                          //   console.log(value);
                          //   return value?.toFixed();
                          // },
                          fontSize: 12,
                          color: '#fff',
                          offsetY: -15
                        },
                        value: {
                          offsetY: -1, // -8 worked for me
                          color: '#fff'
                        }
                      }
                    }
                  }
                },

                colors: ['#04d25b', '#e9ba5a'],
                stroke: {
                  show: false
                },
                dataLabels: {
                  enabled: false
                }
              }}
            />
          </div>
          {/* <div className="donut-chart">
            <ReactApexChart options={chartOptions} series={chartSeries} type="donut" height={350} />
          </div> */}
        </Grid>
      </Grid>
    </Card>
  );
};

export default MileStoneDonutChart;
