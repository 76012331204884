import { GET_WALLET_DETAILS } from './constants';

const initialState = {
  walletDetails: null
};

export default function wallet(state = initialState, { type, payload }) {
  switch (type) {
    case GET_WALLET_DETAILS:
      return {
        ...state,
        walletDetails: payload
      };
    default:
      return state;
  }
}
