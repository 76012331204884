import { combineReducers } from 'redux';

// reducer import
import customizationReducer from './customizationReducer';
import auth from './auth/reducer';
import packages from './packages/reducer';
import levels from './levels/reducer';
import investments from './investments/reducer';
import settings from './settings/reducer';
import users from './users/reducer';
import paymentQR from './paymentQR/reducer';
import withdraw from './withdraw/reducer';
import chat from './chat/reducer';
import mileStone from './milestone/reducer';
import faq from './faq/reducer';
import wallet from './wallet/reducer';
import usersKYC from './KYCUsers/reducer';
import teampool from './teampool/reducer';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
  customization: customizationReducer,
  auth,
  packages,
  levels,
  investments,
  settings,
  users,
  paymentQR,
  withdraw,
  chat,
  mileStone,
  faq,
  wallet,
  usersKYC,
  teampool
});

export default reducer;
