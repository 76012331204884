import api from 'utils/api';
import { GET_PACKAGES } from './constants';

export const getPackages = () => async (dispatch) => {
  try {
    const { data } = await api.post('/get_package');
    dispatch({
      type: GET_PACKAGES,
      payload: data?.arrList
    });
  } catch (error) {
    console.log(error);
  }
};
export const createPackage = (values) => async (dispatch) => {
  try {
    const { data } = await api.post('/create_package', values);
    dispatch(getPackages());
  } catch (error) {
    console.log(error);
  }
};
export const deletePackage = (body) => async (dispatch) => {
  const { data } = await api.post('/common_api', body);
  await dispatch(getPackages());
};

export const updatePackage = (body) => async (dispatch) => {
  const { data } = await api.post('/update_package', body);
  await dispatch(getPackages());
};
