import { GET_FAQ_LIST } from './constants';

const initialState = {
  faqList: null
};

export default function faq(state = initialState, { type, payload }) {
  switch (type) {
    case GET_FAQ_LIST:
      return {
        ...state,
        faqList: payload
      };
    default:
      return state;
  }
}
