import { Button, CardMedia, Grid, Typography } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { approveInvestment } from 'store/investments/actions';
import MainCard from 'ui-component/cards/MainCard';
import YesOrNoModal from 'ui-component/modals/YesOrNoModal';

const styleLabel = {
  color: '#1c87c9'
};

const DetailedInvestment = () => {
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [objId, setObjId] = useState('');
  const handleOpenModal = () => {
    setConfirmationModalOpen(true);
  };
  const handleCloseModal = () => {
    setConfirmationModalOpen(false);
  };

  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { allInvestments } = useSelector((state) => state?.investments);
  const currentInvestment = allInvestments.find((investment) => investment._id === params.investmentId);
  const [isLoading, setIsLoading] = useState(false);
  const handleClickApprove = async (id) => {
    setIsLoading(true);
    await dispatch(approveInvestment({ _id: id }));
    setIsLoading(false);
    navigate('/investment');
  };
  const handleReject = async (id, rejectMsg) => {
    await dispatch(approveInvestment({ _id: id, strStatus: 'S', rejectMsg: rejectMsg }));
    navigate('/investment');
  };
  const handleClickReject = async (id) => {
    setObjId(id);
    handleOpenModal();
    // await dispatch(approveInvestment({ _id: id, strStatus: 'R' }));
    // navigate('/investment');
  };
  console.log('currentInvestment', currentInvestment);
  return (
    <MainCard title="Investment Details">
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Grid container>
            <Grid item xs={12} md={6} display={'flex'}>
              <Typography style={styleLabel}>Username :</Typography>
              <Typography>{currentInvestment?.objUser[0]?.strName}</Typography>
            </Grid>
            {/* <Grid item xs={12} md={6} display={'flex'}>
              <Typography style={styleLabel}>Investor Email :</Typography>
              <Typography>{currentInvestment?.objUser[0]?.strEmail}</Typography>
            </Grid> */}
          </Grid>
        </Grid>
        <Grid item xs={12} md={12}>
          <Grid container>
            <Grid item xs={12} md={6} display={'flex'}>
              <Typography style={styleLabel}>Investor Name :</Typography>
              <Typography>{currentInvestment?.objUser[0]?.strFullName}</Typography>
            </Grid>
            <Grid item xs={12} md={6} display={'flex'}>
              <Typography style={styleLabel}>Investor Email :</Typography>
              <Typography>{currentInvestment?.objUser[0]?.strEmail}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12}>
          <Grid container>
            <Grid item xs={12} md={6} display={'flex'}>
              <Typography style={styleLabel}>Investor Phone :</Typography>
              <Typography>{currentInvestment?.objUser[0]?.strMobileNo}</Typography>
            </Grid>
            <Grid item xs={12} md={6} display={'flex'}>
              <Typography style={styleLabel}>Investor Referance Code :</Typography>
              <Typography>{currentInvestment?.objUser[0]?.strRefCode}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12}>
          <Grid container>
            <Grid item xs={12} md={6} display={'flex'}>
              <Typography style={styleLabel}>Plan Name :</Typography>
              <Typography>{currentInvestment?.objPackage[0]?.strName}</Typography>
            </Grid>
            <Grid item xs={12} md={6} display={'flex'}>
              <Typography style={styleLabel}>Plan Amount :</Typography>
              <Typography>
                {currentInvestment?.objPackage[0]?.intDepositeAmount} - {currentInvestment?.objPackage[0]?.intToDepositeAmount}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12}>
          <Grid container>
            <Grid item xs={12} md={6} display={'flex'}>
              <Typography style={styleLabel}>Benefit Percentage :</Typography>
              <Typography>{currentInvestment?.objPackage[0]?.strPercentageRane}</Typography>
            </Grid>
            <Grid item xs={12} md={6} display={'flex'}>
              <Typography style={styleLabel}>Total Amount :</Typography>
              <Typography>{currentInvestment?.intTotalAmt}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12}>
          <Grid container>
            <Grid item xs={12} md={6} display={'flex'}>
              <Typography style={styleLabel}>Investment Type :</Typography>
              <Typography>
                {currentInvestment?.strInvestmentType && currentInvestment?.strInvestmentType === 'topup'
                  ? 'Topup Investment'
                  : 'Normal Investment'}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} display={'flex'}>
              <Typography style={styleLabel}>Plus :</Typography>
              <Typography>
                {currentInvestment?.investmentLockType && currentInvestment?.investmentLockType === 'active' ? 'Yes' : 'No'}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12}>
          <Grid container>
            <Grid item xs={12} md={6} display={'flex'}>
              <Typography style={styleLabel}>Created Time :</Typography>
              <Typography>{moment(currentInvestment?.strCreatedTime).format(' MMMM Do YYYY, h:mm a')}</Typography>
            </Grid>
            {/* <Grid item xs={12} md={6} display={'flex'}>
              <Typography style={styleLabel}>Total Amount :</Typography>
              <Typography>{currentInvestment?.intTotalAmt}</Typography>
            </Grid> */}
          </Grid>
        </Grid>
        {currentInvestment?.strTransProf && (
          <Grid item xs={12} md={6}>
            <Typography style={styleLabel}>Transaction Proof :</Typography>
            <img src={currentInvestment?.strTransProf} alt="" style={{ maxWidth: '100%', maxHeight: '100%' }} />
            {/* <CardMedia sx={{ height: 240 }} image={currentInvestment?.strTransProf} title="green iguana" /> */}
          </Grid>
        )}

        <Grid item xs={12} md={12}>
          <Grid container>
            {(currentInvestment?.chrStatus === 'F' || currentInvestment?.chrStatus === 'P') && (
              <>
                <Button variant="contained" disabled={isLoading} onClick={() => handleClickApprove(currentInvestment?._id)}>
                  Approve
                </Button>
                <Button variant="contained" onClick={() => handleClickReject(currentInvestment?._id)}>
                  Reject
                </Button>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
      <YesOrNoModal
        isOpen={confirmationModalOpen}
        onClose={handleCloseModal}
        title={'Are you sure ?'}
        func={handleReject}
        objectId={objId}
      />
    </MainCard>
  );
};

export default DetailedInvestment;
