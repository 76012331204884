import {
  Button,
  Card,
  Grid,
  IconButton,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getInvestment, getPlusInvestment } from 'store/investments/actions';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { CHANGE_CHRSTATUSINV, CHANGE_INVESTMENTS_LIST_PAGE, CHANGE_PLUS_PAGE, CHANGE_PLUS_STATUS } from 'store/investments/constants';
import CustomPagination from 'ui-component/CustomPagination';
import PaginationRedux from 'ui-component/PaginationRedux';

const PlusInvestments = () => {
  // const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(30);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const [investmentStatus, setInvestmentStatus] = useState('F');
  const { allPlusInvestments, plusStatus, allPlusInvestmentsCount, plusPage } = useSelector((state) => state?.investments);

  const handleChange = (event, newAlignment) => {
    // setInvestmentStatus(newAlignment);
    dispatch({
      type: CHANGE_PLUS_STATUS,
      payload: newAlignment
    });
  };
  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
  useEffect(() => {
    console.log('working investment useEffect');
    dispatch(getPlusInvestment({ plusStatus: plusStatus, page: plusPage, limit }));
  }, [plusStatus, plusPage, limit]);
  // const { allInvestments, chrStatusInv } = useSelector((state) => state?.investments);
  const handleClickInvestment = (id) => {
    navigate(`/plusinvestment/detailed/${id}`);
  };
  function groupInvestmentsByDate(investments) {
    const groupedInvestments = {};
    investments?.forEach((investment) => {
      const date = moment(investment.strCreatedTime).format('MMMM Do YYYY');
      if (!groupedInvestments[date]) {
        groupedInvestments[date] = [];
      }
      groupedInvestments[date].push(investment);
    });
    return groupedInvestments;
  }
  const groupedInvestments = groupInvestmentsByDate(allPlusInvestments);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card sx={{ padding: '16px' }}>
          <Typography variant="h3">Plus Investments</Typography>
        </Card>
      </Grid>
      <Grid item xs={12} md={12}>
        <Grid container>
          <ToggleButtonGroup color="primary" value={plusStatus} exclusive onChange={handleChange} aria-label="Platform">
            <ToggleButton value="active">Active</ToggleButton>
            <ToggleButton value="inactive">Inactive</ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </Grid>
      {matchDownSM && (
        <Grid item xs={12} md={12}>
          {/* <Grid container spacing={2}>
              {allInvestments?.map((investment, i) => (
                <Grid item xs={12} key={i}>
                  <Card onClick={() => handleClickInvestment(investment?._id)}>
                    <Grid container p={2}>
                      <Grid item xs={10}>
                        <Typography>Name : {investment?.objUser[0]?.strFullName}</Typography>
                        <Typography>Amount : {investment?.intTotalAmt}</Typography>
                        <Typography>Plan : {investment?.objPackage[0]?.strName}</Typography>
                        <Typography>Applied time : {moment(investment?.strCreatedTime).format(' MMMM Do YYYY, h:mm a')}</Typography>
                      </Grid>
                      <Grid item xs={2} display={'flex'} alignItems={'center'}>
                        <IconButton>
                          <ArrowForwardIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
              ))}
            </Grid> */}
          <Grid container spacing={2}>
            {Object.keys(groupedInvestments).map((date, i) => (
              <Grid item xs={12} key={i}>
                <Typography variant="h6" style={{ color: 'green', marginBottom: '3px' }}>
                  {date}
                </Typography>
                {groupedInvestments[date]?.map((investment, j) => (
                  <Card key={j} onClick={() => handleClickInvestment(investment._id)} sx={{ marginTop: '16px' }}>
                    <Grid container p={2}>
                      <Grid item xs={10}>
                        <Typography>Name : {investment?.objUser[0]?.strFullName}</Typography>
                        <Typography>Amount : {investment?.intTotalAmt}</Typography>
                        <Typography>Plan : {investment?.objPackage[0]?.strName}</Typography>
                        <Typography>Applied time : {moment(investment?.strCreatedTime).format(' MMMM Do YYYY, h:mm a')}</Typography>
                      </Grid>
                      <Grid item xs={2} display={'flex'} alignItems={'center'}>
                        <IconButton>
                          <ArrowForwardIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Card>
                ))}
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}
      {!matchDownSM && (
        <Grid item xs={12} md={12}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Plan</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allPlusInvestments?.map((investment, i) => (
                  <TableRow key={i}>
                    <TableCell>{investment?.objUser[0]?.strFullName}</TableCell>
                    <TableCell>{investment?.intTotalAmt}</TableCell>
                    <TableCell>{investment?.objPackage[0]?.strName}</TableCell>
                    <TableCell>
                      <Button onClick={() => handleClickInvestment(investment?._id)} variant="contained">
                        View
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      )}
      <Grid item xs={12}>
        {/* <CustomPagination count={allInvestmentsCount} page={page} rowsPerPage={limit} setRowsPerPage={setLimit} onPageChange={setPage} /> */}
        <PaginationRedux
          count={allPlusInvestmentsCount}
          page={plusPage}
          rowsPerPage={limit}
          setRowsPerPage={setLimit}
          type={CHANGE_PLUS_PAGE}
        />
      </Grid>
    </Grid>
  );
};

export default PlusInvestments;
