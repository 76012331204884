import { Card, Grid, Typography } from '@mui/material';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import Chart from 'react-apexcharts';
import { useDispatch, useSelector } from 'react-redux';
import { getSettings } from 'store/settings/actions';
import api from 'utils/api';
import { useTheme, styled } from '@mui/material/styles';
import MainCard from 'ui-component/cards/MainCard';

const CardWrapper = styled(MainCard)(({ theme }) => ({
  background: 'linear-gradient(to right , #a8a9ad,rgb(0,145,106))',
  border: 'none',
  color: '#000',
  overflow: 'hidden',
  position: 'relative',
  '&>div': {
    position: 'relative',
    zIndex: 5
  },
  '&:after': {
    content: '""',
    position: 'absolute',
    width: 210,
    height: 210,
    background: '#a8a9ad',
    borderRadius: '50%',
    zIndex: 1,
    top: -85,
    left: -95,
    [theme.breakpoints.down('sm')]: {
      top: -105,
      right: -140
    }
  },
  '&:before': {
    content: '""',
    position: 'absolute',
    zIndex: 1,
    width: 210,
    height: 210,
    background: '#a8a9ad',
    borderRadius: '50%',
    top: -125,
    left: -15,
    opacity: 0.5,
    [theme.breakpoints.down('sm')]: {
      top: -155,
      right: -70
    }
  }
}));

const TeampoolBar = ({ indirectReferralInvestment, setInfinitySalaryAmt }) => {
  const [teampoolDatas, setTeampoolDatas] = useState([]);
  const [maxValue, setMaxValue] = useState(0);
  const [mileStoneData, setMileStoneData] = useState(null);
  const [valuePerc, setValuePerc] = useState(0.15);
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchTeamPoolData = async () => {
      try {
        const body = {
          strType: 'infinity',
          type: 'commonList'
        };
        const { data } = await api.post('/common_api', body);
        setTeampoolDatas(data?.arrList);
      } catch (error) {
        console.log(error);
      }
    };
    fetchTeamPoolData();
  }, []);
  useEffect(() => {
    dispatch(getSettings({ strType: 'settings', type: 'commonList' }));
  }, []);
  const { settingsValues } = useSelector((state) => state?.settings);
  useEffect(() => {
    setValuePerc(settingsValues[6]?.intPercentage);
  }, [settingsValues]);
  console.log('teampoolDatas', teampoolDatas);
  console.log('milestoneData', mileStoneData);
  useEffect(() => {
    let points = [];
    if (teampoolDatas?.length > 0) {
      let maximumValue = teampoolDatas[0]?.intAmount;
      let nextMileStone = teampoolDatas[0];
      for (let i = 0; i < teampoolDatas?.length; i++) {
        if ((indirectReferralInvestment * valuePerc) / 100 < teampoolDatas[i].intAmount) {
          maximumValue = teampoolDatas[i].intAmount;
          nextMileStone = teampoolDatas[i];
          if (i === 0) {
            setInfinitySalaryAmt(0);
          } else {
            setInfinitySalaryAmt(teampoolDatas[i - 1].intAmount);
          }
          break;
        }
      }
      setMaxValue(maximumValue);
      setMileStoneData(nextMileStone);
    } else {
      points.push({ value: 0, label: 'No milestones available' });
    }
  }, [teampoolDatas, indirectReferralInvestment]);

  const series = [
    {
      name: `Achieved ${((indirectReferralInvestment * parseFloat(valuePerc)) / 100).toFixed(2)}`,
      data: [indirectReferralInvestment ? parseFloat(((indirectReferralInvestment * valuePerc) / 100).toFixed(2)) : 0]
    },
    {
      name: `Balance ${
        mileStoneData?.intAmount ? parseFloat((mileStoneData?.intAmount - (indirectReferralInvestment * valuePerc) / 100).toFixed(2)) : 100
      }`,
      data: [
        mileStoneData?.intAmount ? parseFloat((mileStoneData?.intAmount - (indirectReferralInvestment * valuePerc) / 100).toFixed(2)) : 100
      ]
    }
  ];
  const options = {
    chart: {
      height: 80, // Adjust the height of the bar as needed
      type: 'bar',
      stacked: true,
      stackType: '100%',
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: '100%'
      }
    },
    dataLabels: {
      enabled: false
    },
    xaxis: {
      categories: ['Achievement'],
      labels: {
        show: false
      }
    },
    yaxis: {
      labels: {
        show: false
      }
    },

    colors: ['#1e88e5', '#fff']
  };
  return (
    <CardWrapper>
      <Grid container>
        <Grid item xs={12} md={12}>
          <Grid container>
            <Grid item xs={6} md={6}>
              <Grid container>
                <Grid item xs={12} md={12}>
                  <Typography sx={{ color: 'black' }} variant="h3">
                    Infinity Salary
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Typography sx={{ color: 'black' }} variant="h4">
                    ${indirectReferralInvestment ? parseFloat(((indirectReferralInvestment * valuePerc) / 100).toFixed(2)) : 0}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} md={6}>
              <Grid container>
                <Grid item xs={12} md={12} display={'flex'} justifyContent={'right'}>
                  <Typography sx={{ color: 'black', fontSize: '10px' }}>Minimum Investment : ${mileStoneData?.intMinInvestment}</Typography>
                </Grid>
                <Grid item xs={12} md={12} display={'flex'} justifyContent={'right'}>
                  <Typography sx={{ color: 'black', fontSize: '10px' }}>
                    Min Direct Referrals : {mileStoneData?.intMinDirectReferral}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Chart options={options} series={series} type="bar" height={80} />
        <Grid item xs={12} md={12} display={'flex'} justifyContent={'center'}>
          <Typography sx={{ color: 'black', fontSize: '10px' }}>
            Next Infinity Milestone : ${mileStoneData?.intAimAmount.toFixed(2)}
          </Typography>
        </Grid>
      </Grid>
    </CardWrapper>
  );
};

export default TeampoolBar;
