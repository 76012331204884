import { Avatar, Button, CardMedia, Divider, Grid, Typography } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { approveKYCUser } from 'store/KYCUsers/actions';
import MainCard from 'ui-component/cards/MainCard';

const KYCUserDetailed = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // useEffect(() => {
  //   dispatch(getUsersList({ chrStatus: 'N' }));
  // }, []);
  const { usersKYCList } = useSelector((state) => state?.usersKYC);
  const currentUser = usersKYCList?.find((user) => user._id === params.userId);
  //   const handleClickApprove = async (id) => {
  //     await dispatch(approveUser({ _id: id }));
  //     navigate('/users');
  //   };
  const handleClickVerifyKYC = async (id) => {
    await dispatch(approveKYCUser({ _id: id, strKYCStatus: 'A' }));
    // navigate('/users');
  };
  const handleClickRejectKYC = async (id) => {
    await dispatch(approveKYCUser({ _id: id, strKYCStatus: 'R' }));
  };
  console.log('currentUser', currentUser);
  return (
    <MainCard title="User Details">
      <Grid container>
        <Grid item xs={12} md={5}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} display={'flex'} justifyContent={'center'}>
              <Avatar alt="Remy Sharp" src={currentUser?.strProfileUrl} sx={{ width: 170, height: 170 }} />
            </Grid>
            <Grid item xs={12} md={12} display={'flex'} justifyContent={'center'}>
              <Typography variant="h3">{currentUser?.strFullName}</Typography>
            </Grid>

            <Grid item xs={12} md={12}>
              <Divider />
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography>Username : {currentUser?.strName}</Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography>Email : {currentUser?.strEmail}</Typography>
            </Grid>

            <Grid item xs={12} md={12}>
              <Typography>Phone : {currentUser?.strMobileNo}</Typography>
            </Grid>
            {currentUser?.strRefCodeBy && (
              <Grid item xs={12} md={12}>
                <Typography>Refferred code : {currentUser?.strRefCodeBy}</Typography>
              </Grid>
            )}

            <Grid item xs={12} md={12}>
              <Typography>Refferral Code : {currentUser?.strRefCode}</Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography>Id Proof : </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <CardMedia sx={{ height: 240 }} image={currentUser?.strIDUrl} title="green iguana" />
            </Grid>
            <Grid item xs={12} md={12}>
              <CardMedia sx={{ height: 240 }} image={currentUser?.strIdProof2Url} title="green iguana" />
            </Grid>
            {currentUser?.strKYCStatus === 'NA' && (
              <Grid item xs={12} md={12}>
                <Button onClick={() => handleClickVerifyKYC(currentUser?._id)} variant="contained">
                  Verify KYC
                </Button>
                <Button variant="outlined" onClick={() => handleClickRejectKYC(currentUser?._id)}>
                  Reject
                </Button>
              </Grid>
            )}

            {currentUser?.strKYCStatus === 'A' && (
              <Grid item xs={12} md={12}>
                <Typography>KYC Verified</Typography>
              </Grid>
            )}
            {currentUser?.strKYCStatus === 'R' && (
              <Grid item xs={12} md={12}>
                <Typography>KYC Verification Failed</Typography>
              </Grid>
            )}
            {/* {currentUser?.chrStatus === 'P' && (
              <Grid item xs={12} md={12}>
                <Button onClick={() => handleClickApprove(currentUser?._id)} variant="contained">
                  Approve User
                </Button>
              </Grid>
            )} */}
          </Grid>
        </Grid>
        <Grid item xs={12} md={7}></Grid>
      </Grid>
    </MainCard>
  );
};

export default KYCUserDetailed;
