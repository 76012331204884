import api from 'utils/api';
import { GET_WITHDRAWALS } from './constants';

// export const getReturnData = (body) => async (dispatch) => {
//   try {
//     const { data } = await api.post('/get_return_data');
//     console.log(data);
//     dispatch({
//       type: GET_RETURN_DATA,
//       payload: data?.objSettings
//     });
//   } catch (error) {
//     console.log(error);
//   }
// };
// export const createWithdrawalReq = (body) => async () => {
//   try {
//     console.log(body);
//     const { data } = await api.post('/create_return', body);
//     console.log(data);
//   } catch (error) {
//     console.log(error);
//   }
// };\
export const getWithdrawals = (body) => async (dispatch) => {
  try {
    const { data } = await api.post('/get_return_list', body);
    dispatch({
      type: GET_WITHDRAWALS,
      payload: data
    });
  } catch (error) {
    console.log(error);
  }
};
export const approveWithdrawal = (body) => async (dispatch) => {
  try {
    await api.post('/approve_returns', body);
    await dispatch(getWithdrawals({ chrStatus: 'P' }));
  } catch (error) {
    console.log(error);
  }
};
