import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import io from 'socket.io-client';

const Chat = () => {
  const [socket, setSocket] = useState(null);
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const { user } = useSelector((state) => state?.auth);
  useEffect(() => {
    if (!user.strToken) {
      return;
    }
    // Initialize the WebSocket connection
    const socketConnection = io('http://localhost:4010', {
      query: {
        token: user.strToken // Replace with your actual token
      }
    });

    setSocket(socketConnection);

    return () => {
      // Disconnect the WebSocket when the component unmounts
      socketConnection.disconnect();
    };
  }, [user]);
  console.log('socket', socket);
  useEffect(() => {
    if (!socket) return;

    // Listen for the 'connect' event
    socket.on('connect', () => {
      console.log('Connected to the Socket.io server');
    });
    socket.emit('join_room', {
      strChatId: '651411223ea2e0e9983ed176',
      strType: 'private'
    });
    // Listen for incoming messages
    socket.on('get_message', (message) => {
      //   setMessages((prevMessages) => [...prevMessages, message]);
      console.log(message);
    });

    // Listen for chat history
    socket.on('chat_history', (history) => {
      //   setMessages(history);
      console.log(history);
    });

    // Listen for 'disconnect' event
    socket.on('disconnect', () => {
      console.log('Disconnected from the Socket.io server');
    });
  }, [socket]);
  const sendMessage = () => {
    if (inputMessage.trim() === '') return;

    const chatId = '651411223ea2e0e9983ed176'; // Replace with your chat ID

    socket.emit('send_message', {
      strChatId: chatId,
      strMessageType: 'text',
      strType: 'private',
      strMessage: inputMessage
    });

    setInputMessage('');
  };
  return (
    <div>
      {/* <div>
        {messages?.map((message, index) => (
          <div key={index}>{message.strMessage}</div>
        ))}
      </div> */}
      <input type="text" value={inputMessage} onChange={(e) => setInputMessage(e.target.value)} />
      <button onClick={sendMessage}>Send</button>
    </div>
  );
};

export default Chat;
