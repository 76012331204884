import api from 'utils/api';
import { GET_PAYMENT_QRS } from './constants';

export const getPaymentQRs = (body) => async (dispatch) => {
  try {
    const { data } = await api.post('/common_api', body);
    dispatch({
      type: GET_PAYMENT_QRS,
      payload: data?.arrList
    });
  } catch (error) {
    console.log(error);
  }
};
export const addPaymentQR = (body) => async (dispatch) => {
  const newBody = {
    strType: 'payment_methodes',
    type: 'commonCreate',
    objDocument: body
  };
  try {
    const { data } = await api.post('/common_api', newBody);
    dispatch(getPaymentQRs({ strType: 'payment_methodes', type: 'commonList' }));
  } catch (error) {
    console.log(error);
  }
};
export const deleteQR = (body) => async (dispatch) => {
  try {
    const { data } = await api.post('/common_api', body);
    dispatch(getPaymentQRs({ strType: 'payment_methodes', type: 'commonList' }));
  } catch (error) {
    console.log(error);
  }
};
