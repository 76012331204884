import {
  CHANGE_CHRSTATUSINV,
  CHANGE_INVESTMENTS_LIST_PAGE,
  CHANGE_PLUS_PAGE,
  CHANGE_PLUS_STATUS,
  CHANGE_TOPUP_CHRSTATUSINV,
  CHANGE__USERS_TOTAL_INVESTMENTS_LIST_PAGE,
  GET_INVESTMENTS,
  GET_PLUS_INVESTMENTS,
  GET_TOPUP_LOGS,
  GET_USERS_TOTAL_INVESTMENTS,
  GET_ZERO_INVESTMENTS
} from './constants';

const initialState = {
  allInvestments: [],
  allPlusInvestments: [],
  zeroInvestmentUsers: [],
  allPlusInvestmentsCount: null,
  allInvestmentsCount: null,
  plusStatus: 'active',
  chrStatusInv: 'F',
  topUpChrStatus: 'P',
  topUpInvestments: [],
  topUpInvestmentsCount: null,
  page: 1,
  plusPage: 1,
  usersTotalInvestment: [],
  usersTotalInvestmentPage: 1,
  usersTotalInvestmentCount: null
};

export default function investments(state = initialState, { type, payload }) {
  switch (type) {
    case GET_USERS_TOTAL_INVESTMENTS:
      return {
        ...state,
        usersTotalInvestment: payload?.arrList,
        usersTotalInvestmentCount: payload?.count
      };
    case GET_INVESTMENTS:
      return {
        ...state,
        allInvestments: payload?.arrList,
        allInvestmentsCount: payload?.count
      };
    case GET_ZERO_INVESTMENTS:
      return {
        ...state,
        zeroInvestmentUsers: payload?.arrList
      };
    case GET_PLUS_INVESTMENTS:
      return {
        ...state,
        allPlusInvestments: payload?.arrList,
        allPlusInvestmentsCount: payload?.count
      };
    case CHANGE_CHRSTATUSINV:
      return {
        ...state,
        chrStatusInv: payload
      };
    case CHANGE_TOPUP_CHRSTATUSINV:
      return {
        ...state,
        topUpChrStatus: payload
      };
    case GET_TOPUP_LOGS:
      return {
        ...state,
        topUpInvestments: payload?.arrList,
        topUpInvestmentsCount: payload?.count
      };
    case CHANGE_INVESTMENTS_LIST_PAGE:
      return {
        ...state,
        page: payload
      };
    case CHANGE__USERS_TOTAL_INVESTMENTS_LIST_PAGE:
      return {
        ...state,
        usersTotalInvestmentPage: payload
      };
    case CHANGE_PLUS_STATUS:
      return {
        ...state,
        plusStatus: payload
      };
    case CHANGE_PLUS_PAGE:
      return {
        ...state,
        plusPage: payload
      };
    default:
      return state;
  }
}
