// assets
import { IconBrandChrome, IconHelp, IconDashboard } from '@tabler/icons';
// constant
const icons = { IconBrandChrome, IconHelp, IconDashboard };

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const other = {
  id: 'sample-docs-roadmap',
  type: 'group',
  children: [
    {
      id: 'default',
      title: 'Dashboard',
      type: 'item',
      url: '/dashboard',
      icon: icons.IconDashboard,
      breadcrumbs: false
    },
    {
      id: 'users',
      title: 'Users',
      type: 'item',
      url: '/users',
      icon: icons.IconDashboard,
      breadcrumbs: false
    },
    {
      id: 'kycstatus',
      title: 'KYC Status',
      type: 'item',
      url: '/kycstatus',
      icon: icons.IconDashboard,
      breadcrumbs: false
    },
    // {
    //   id: 'profile',
    //   title: 'Profile',
    //   type: 'item',
    //   url: '/profile',
    //   icon: icons.IconDashboard,
    //   breadcrumbs: false
    // },
    {
      id: 'investment',
      title: 'Investments',
      type: 'item',
      url: '/investment',
      icon: icons.IconDashboard,
      breadcrumbs: false
    },
    {
      id: 'plusinvestment',
      title: 'Plus Investments',
      type: 'item',
      url: '/plusinvestment',
      icon: icons.IconDashboard,
      breadcrumbs: false
    },
    {
      id: 'account',
      title: 'Account Settings',
      type: 'item',
      url: '/account',
      icon: icons.IconDashboard,
      breadcrumbs: false
    },
    {
      id: 'chat',
      title: 'Chat',
      type: 'item',
      url: '/chat',
      icon: icons.IconDashboard,
      breadcrumbs: false
    },
    {
      id: 'milestone',
      title: 'Milestone',
      type: 'item',
      url: '/milestone',
      icon: icons.IconDashboard,
      breadcrumbs: false
    },
    {
      id: 'plans',
      title: 'Plans',
      type: 'item',
      url: '/plans',
      icon: icons.IconBrandChrome,
      breadcrumbs: false
    },

    {
      id: 'withdrawals',
      title: 'Withdrawals',
      type: 'item',
      url: '/withdraw',
      icon: icons.IconBrandChrome,
      breadcrumbs: false
    },
    {
      id: 'transactions',
      title: 'Transactions',
      type: 'item',
      url: '/transactions',
      icon: icons.IconBrandChrome,
      breadcrumbs: false
    },
    {
      id: 'faq',
      title: 'Faq',
      type: 'item',
      url: '/faq',
      icon: icons.IconBrandChrome,
      breadcrumbs: false
    },
    {
      id: 'dashboarduserslist',
      title: 'User Dashboard',
      type: 'item',
      url: '/dashboarduserslist',
      icon: icons.IconBrandChrome,
      breadcrumbs: false
    },
    {
      id: 'milestoneachievedusers',
      title: 'Milestone Achieved Users',
      type: 'item',
      url: '/achievedmilestones',
      icon: icons.IconBrandChrome,
      breadcrumbs: false
    },
    {
      id: 'salarylogs',
      title: 'Salary History',
      type: 'item',
      url: '/salarylogs',
      icon: icons.IconBrandChrome,
      breadcrumbs: false
    },
    {
      id: 'Infinity',
      title: 'Infinity',
      type: 'item',
      url: '/infinity',
      icon: icons.IconBrandChrome,
      breadcrumbs: false
    }
  ]
};

export default other;
