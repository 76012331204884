import api from 'utils/api';
import { GET_SETTINGS } from './constants';

export const getSettings = (body) => async (dispatch) => {
  try {
    const { data } = await api.post('/common_api', body);
    dispatch({
      type: GET_SETTINGS,
      payload: data?.arrList
    });
  } catch (error) {
    console.log(error);
  }
};
export const updateSettings = (body) => async (dispatch) => {
  try {
    const { data } = await api.post('/common_api', body);
    dispatch(getSettings({ strType: 'settings', type: 'commonList' }));
  } catch (error) {
    console.log(error);
  }
};
