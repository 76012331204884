import {
  Button,
  Card,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getWithdrawals } from 'store/withdraw/actions';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import SearchComponent from 'ui-component/SearchComponent';
import CustomPagination from 'ui-component/CustomPagination';
import { CHANGE_WITHDRAWAL_STATUS, CHANGE_WITHDRAW_LIST_PAGE } from 'store/withdraw/constants';
import PaginationRedux from 'ui-component/PaginationRedux';

const Withdrawals = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
  const { withdrawalList, withdrawalListCount, chrStatusWithdrawal, page } = useSelector((state) => state?.withdraw);
  const [withdrawalStatus, setWithdrawalStatus] = useState('P');
  const [strSearch, setStrSearch] = useState('');
  // const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(30);
  const handleChange = (event, newAlignment) => {
    dispatch({
      type: CHANGE_WITHDRAWAL_STATUS,
      payload: newAlignment
    });
  };
  useEffect(() => {
    dispatch(getWithdrawals({ chrStatus: chrStatusWithdrawal, strSearch: strSearch, page: page, limit: limit }));
  }, [chrStatusWithdrawal, strSearch, page, limit]);

  const handleClickWithdrawal = (id) => {
    navigate(`/withdraw/detailedwithdrawal/${id}`);
  };
  function groupWithdrawalsByDate(investments) {
    const groupedInvestments = {};
    investments?.forEach((investment) => {
      const date = moment(investment.strCreatedTime).format('MMMM Do YYYY');
      if (!groupedInvestments[date]) {
        groupedInvestments[date] = [];
      }
      groupedInvestments[date].push(investment);
    });
    return groupedInvestments;
  }
  const groupedWithdrawals = groupWithdrawalsByDate(withdrawalList);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <Card sx={{ padding: '16px' }}>
          <Typography variant="h3">Withdrawal Requests</Typography>
        </Card>
      </Grid>
      <Grid item xs={12} md={12}>
        <Grid container display={"flex"} justifyContent={"space-between"}>
          <ToggleButtonGroup color="primary" value={chrStatusWithdrawal} exclusive onChange={handleChange} aria-label="Platform">
            <ToggleButton value="P">Pending</ToggleButton>
            <ToggleButton value="N">Approved</ToggleButton>
            <ToggleButton value="R">Rejected</ToggleButton>
          </ToggleButtonGroup>
          <Button variant='contained' onClick={()=>navigate("/withdrawals/manualwithdrawal")}>Manual Withdrawals</Button>
        </Grid>
      </Grid>
      <Grid item xs={12} md={12}>
        <SearchComponent searchValue={strSearch} setSearchValue={setStrSearch} />
      </Grid>
      {matchDownSM && (
        <Grid item xs={12} md={12}>
          <Grid container spacing={2}>
            {Object.keys(groupedWithdrawals).map((date, i) => (
              <Grid item xs={12} key={i}>
                <Typography variant="h6" style={{ color: 'green', marginBottom: '3px' }}>
                  {date}
                </Typography>
                {groupedWithdrawals[date]?.map((withdrawal, j) => (
                  <Card key={j} onClick={() => handleClickWithdrawal(withdrawal?._id)} sx={{ marginTop: '16px' }}>
                    <Grid container p={2}>
                      <Grid item xs={10}>
                        <Typography>Name : {withdrawal?.objUser?.strFullName}</Typography>
                        <Typography>Type : {withdrawal?.strType}</Typography>
                        <Typography>Amount : {withdrawal?.intPayableAmt}</Typography>
                      </Grid>
                      <Grid item xs={2} display={'flex'} alignItems={'center'}>
                        <IconButton>
                          <ArrowForwardIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Card>
                ))}
              </Grid>
            ))}
            {/* {withdrawalList?.map((withdrawal, i) => (
              <Grid item xs={12} key={i}>
                <Card onClick={() => handleClickWithdrawal(withdrawal?._id)}>
                  <Grid container p={2}>
                    <Grid item xs={10}>
                      <Typography>Name : {withdrawal?.objUser[0]?.strFullName}</Typography>
                      <Typography>Type : {withdrawal?.strType}</Typography>
                      <Typography>Amount : {withdrawal?.intPayableAmt}</Typography>
                    </Grid>
                    <Grid item xs={2} display={'flex'} alignItems={'center'}>
                      <IconButton>
                        <ArrowForwardIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            ))} */}
          </Grid>
        </Grid>
      )}
      {!matchDownSM && (
        <Grid item xs={12} md={12}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {withdrawalList?.map((withdrawal, i) => (
                  <TableRow key={i}>
                    <TableCell>{withdrawal?.objUser?.strFullName}</TableCell>
                    <TableCell>{withdrawal?.strType}</TableCell>
                    <TableCell>{withdrawal?.intPayableAmt}</TableCell>
                    <TableCell>
                      <Button onClick={() => handleClickWithdrawal(withdrawal?._id)} variant="contained">
                        View
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      )}
      <Grid item xs={12} md={12}>
        {/* <CustomPagination count={withdrawalListCount} page={page} rowsPerPage={limit} setRowsPerPage={setLimit} onPageChange={setPage} /> */}
        <PaginationRedux
          count={withdrawalListCount}
          page={page}
          rowsPerPage={limit}
          setRowsPerPage={setLimit}
          type={CHANGE_WITHDRAW_LIST_PAGE}
        />
      </Grid>
    </Grid>
  );
};

export default Withdrawals;
