import { GET_TEAMPOOL_LIST } from './constants';

const initialState = {
  teampoolList: null
};

export default function teampool(state = initialState, { type, payload }) {
  switch (type) {
    case GET_TEAMPOOL_LIST:
      return {
        ...state,
        teampoolList: payload
      };
    default:
      return state;
  }
}
