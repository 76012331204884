import PropTypes from 'prop-types';

// material-ui
import { useTheme, styled } from '@mui/material/styles';
import { Avatar, Box, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import TotalIncomeCard from 'ui-component/cards/Skeleton/TotalIncomeCard';

// assets
import StorefrontTwoToneIcon from '@mui/icons-material/StorefrontTwoTone';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import api from 'utils/api';
import { getSettings } from 'store/settings/actions';

// styles
const CardWrapper = styled(MainCard)(({ theme }) => ({
  overflow: 'hidden',
  position: 'relative',
  '&:after': {
    content: '""',
    position: 'absolute',
    width: 210,
    height: 210,
    background: `linear-gradient(210.04deg, ${theme.palette.warning.dark} -50.94%, rgba(144, 202, 249, 0) 83.49%)`,
    borderRadius: '50%',
    top: -30,
    right: -180
  },
  '&:before': {
    content: '""',
    position: 'absolute',
    width: 210,
    height: 210,
    background: `linear-gradient(140.9deg, ${theme.palette.warning.dark} -14.02%, rgba(144, 202, 249, 0) 70.50%)`,
    borderRadius: '50%',
    top: -160,
    right: -130
  }
}));

// ==============================|| DASHBOARD - TOTAL INCOME LIGHT CARD ||============================== //

const IndirectReferralsAmount = ({ userRefCode, setIndirectReferralInvestment, isLoading }) => {
  const dispatch = useDispatch();
  const [rootUsers, setRootUsers] = useState([]);
  const [secondLevelUsers, setSecondLevelUsers] = useState([]);
  const [initialCall, setInitialCall] = useState(true);

  const [queue, setQueue] = useState([]);

  //   const { user } = useSelector((state) => state?.auth);
  const theme = useTheme();
  useEffect(() => {
    dispatch(getSettings({ strType: 'settings', type: 'commonList' }));
  }, []);
  const { settingsValues } = useSelector((state) => state?.settings);
  const [referalIncome3LevelsLimit, setReferalIncome3LevelsLimit] = useState(0);
  const [referalIncome5LevelsLimit, setReferalIncome5LevelsLimit] = useState(0);
  const [referalIncome7LevelsLimit, setReferalIncome7LevelsLimit] = useState(0);
  const { walletDetails } = useSelector((state) => state?.wallet);
  useEffect(() => {
    setReferalIncome3LevelsLimit(settingsValues[4]?.intInvestmentLimit);
    setReferalIncome5LevelsLimit(settingsValues[9]?.intInvestmentLimit);
    setReferalIncome7LevelsLimit(settingsValues[5]?.intInvestmentLimit);
  }, [settingsValues]);
  const capitalAmount = walletDetails?.arrCapitals[0]?.intAmt ? walletDetails?.arrCapitals[0]?.intAmt : 0;
  const [indirectReferralAmt, setIndirectReferralAmt] = useState(0);
  useEffect(() => {
    // Simulate initial API call for the root users
    async function fetchRootUsers() {
      if (!initialCall) {
        return;
      }
      if (!userRefCode) {
        return;
      }
      try {
        setInitialCall(false);
        const response = await api.post('/get_ref_legs', {
          strRefCode: userRefCode
        });

        if (response.data.success) {
          setRootUsers(response.data.arrList);
        }
      } catch (error) {
        console.error('Error fetching root users:', error);
      }
    }

    fetchRootUsers();
  }, [userRefCode]);
  useEffect(() => {
    if (!rootUsers.length) {
      return;
    }
    if (rootUsers.length > 0) {
      let initialArr = [];
      rootUsers.forEach((user) => {
        initialArr.push({ strRefCode: user.strRefCode, level: 1 });
      });
      setQueue(initialArr);
    }
  }, [rootUsers]);
  const fetchUsersWithReferral = async (referralCode) => {
    try {
      const response = await api.post('/get_ref_legs', {
        strRefCode: referralCode
      });
      if (response.data.success) {
        //   setRootUsers(response.data.arrList);
        return response.data.arrList;
      }
    } catch (error) {
      console.error('Error fetching root users:', error);
    }
  };
  useEffect(() => {
    const pushToQueue = async () => {
      let subUsersLimit = 3;
      if (capitalAmount > referalIncome3LevelsLimit) {
        subUsersLimit = 5; //
      }
      if (capitalAmount > referalIncome5LevelsLimit) {
        subUsersLimit = 7;
      }
      if (capitalAmount > referalIncome7LevelsLimit) {
        subUsersLimit = 10;
      }

      let initialArr = [...queue];
      console.log('initialArray', initialArr);
      let firstElement = queue[0];
      if (firstElement.level >= subUsersLimit) {
        initialArr.shift();
        setQueue(initialArr);
        return;
      }
      let newUsers = await fetchUsersWithReferral(firstElement.strRefCode);
      console.log('newUsers', newUsers);
      if (newUsers.length > 0) {
        let total = 0;
        newUsers.forEach((user) => {
          let userTotal = user.arrInvestMents.reduce((acc, investment) => {
            return acc + investment.intCapitalAmt;
          }, 0);
          total = total + userTotal;
          initialArr.push({ strRefCode: user.strRefCode, level: firstElement.level + 1 });
        });
        setIndirectReferralAmt(indirectReferralAmt + total);
        setIndirectReferralInvestment(indirectReferralAmt + total);
        initialArr.shift();
        setQueue(initialArr);
      } else {
        initialArr.shift();
        setQueue(initialArr);
      }
    };
    if (queue.length > 0) {
      pushToQueue();
    }
  }, [queue]);
  return (
    <>
      {isLoading ? (
        <TotalIncomeCard />
      ) : (
        <CardWrapper border={false} content={false}>
          <Box sx={{ p: 2 }}>
            <List sx={{ py: 0 }}>
              <ListItem alignItems="center" disableGutters sx={{ py: 0 }}>
                <ListItemAvatar>
                  <Avatar
                    variant="rounded"
                    sx={{
                      ...theme.typography.commonAvatar,
                      ...theme.typography.largeAvatar,
                      backgroundColor: theme.palette.warning.light,
                      color: theme.palette.warning.dark
                    }}
                  >
                    <StorefrontTwoToneIcon fontSize="inherit" />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  sx={{
                    py: 0,
                    mt: 0.45,
                    mb: 0.45
                  }}
                  primary={<Typography variant="h4">${indirectReferralAmt}</Typography>}
                  secondary={
                    <Typography
                      variant="subtitle2"
                      sx={{
                        color: theme.palette.grey[500],
                        mt: 0.5
                      }}
                    >
                      Infinity Referral Investments
                    </Typography>
                  }
                />
              </ListItem>
            </List>
          </Box>
        </CardWrapper>
      )}
    </>
  );
};

IndirectReferralsAmount.propTypes = {
  isLoading: PropTypes.bool
};

export default IndirectReferralsAmount;
