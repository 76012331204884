import React, { useState } from 'react';
import { Avatar, Box, Button, Container, Grid, InputAdornment, Paper, TextField } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getMessageHistory } from 'store/chat/actions';
import io from 'socket.io-client';
import { GET_MESSAGE_HISTORY } from 'store/chat/constants';

const ChatUI = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const [socket, setSocket] = useState(null);

  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  //   const [newMessage, setNewMessage] = useState('');
  const { user } = useSelector((state) => state?.auth);
  useEffect(() => {
    dispatch(getMessageHistory({ strChatId: params.chatId }));
    return () => {
      dispatch({
        type: GET_MESSAGE_HISTORY,
        payload: []
      });
    };
  }, []);

  const { messageHistory } = useSelector((state) => state?.chat);
  useEffect(() => {
    setMessages(messageHistory);
  }, [messageHistory]);
  useEffect(() => {
    if (!user.strToken) {
      return;
    }
    // const socketConnection = io('http://localhost:4010', {
    //   query: {
    //     token: user.strToken
    //   }
    // });
    // console.log('working');
    // const socketConnection = io('http://65.0.170.77:4010', {
    //   query: {
    //     token: user.strToken
    //   }
    // });
    const socketConnection = io(process.env.REACT_APP_CHAT_URL, {
      query: {
        token: user.strToken
      }
    });

    setSocket(socketConnection);

    return () => {
      socketConnection.disconnect();
    };
  }, [user]);
  useEffect(() => {
    if (!socket) {
      return;
    }

    socket.on('connect', () => {
      console.log('Connected to the Socket.io server');
    });
    socket.on('error', (error) => {
      console.error('WebSocket error:', error);
    });
    socket.emit('join_room', {
      strChatId: params.chatId,
      strType: 'private'
    });
    socket.on('get_message', (message) => {
      setMessages((oldArray) => [...oldArray, message]);
    });

    socket.on('chat_history', (history) => {
      console.log(history);
    });

    socket.on('disconnect', () => {
      console.log('Disconnected from the Socket.io server');
    });
  }, [socket]);
  const sendMessage = () => {
    if (inputMessage.trim() === '') return;

    socket.emit('send_message', {
      strChatId: params.chatId,
      strMessageType: 'text',
      strType: 'private',
      strMessage: inputMessage
    });

    setInputMessage('');
  };
  const formatMessageDate = (date) => {
    console.log('date', date);
    return date.substring(0, 10);
    // const messageDate = new Date(date);
    // console.log('mesageDate', messageDate);

    // return messageDate.toLocaleDateString('en-US', {
    //   weekday: 'long',
    //   year: 'numeric',
    //   month: 'long',
    //   day: 'numeric'
    // });
  };
  return (
    <Container maxWidth="md" style={{ marginTop: '20px' }}>
      <Paper elevation={3} style={{ padding: '20px' }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="center">
              <Avatar alt="User Avatar" src="/path-to-avatar.jpg" />
              <Box ml={2}>
                <strong>User 1</strong>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box minHeight="300px" overflowY="scroll">
              {messages.map((message, index) => (
                <div
                  key={message._id}
                  style={{
                    justifyContent: message.strUserId === user?._id ? 'right' : 'left',
                    textAlign: message.strUserId === user?._id ? 'right' : 'left',
                    marginBottom: '10px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: message.strUserId === user?._id ? 'flex-end' : 'flex-start'
                  }}
                >
                  {index === 0 ||
                  new Date(messages[index - 1]?.strCreatedTime).toLocaleDateString() !==
                    new Date(message.strCreatedTime).toLocaleDateString() ? (
                    <div style={{ textAlign: 'center', color: '#888', marginBottom: '5px' }}>
                      {formatMessageDate(message.strCreatedTime)}
                    </div>
                  ) : null}
                  {/* <strong>{message.name}</strong> */}
                  <div
                    style={{
                      marginLeft: '5px',
                      color: '#888',
                      textAlign: 'right'
                      // display:
                      //   index > 0 &&
                      //   new Date(messages[index - 1]?.strCreatedTime).toLocaleDateString() ===
                      //     new Date(message.strCreatedTime).toLocaleDateString()
                      //     ? 'none'
                      //     : 'block'
                    }}
                  >
                    {message?.strCreatedTime?.substring(11, 16)}
                  </div>
                  <div
                    style={{
                      backgroundColor: message.strUserId === user?._id ? '#2979ff' : '#f0f0f0',
                      color: message.strUserId === user?._id ? '#fff' : '#333',
                      padding: '8px',
                      borderRadius: '8px',
                      maxWidth: '70%'
                    }}
                  >
                    {message.strMessage}
                  </div>
                </div>
              ))}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Type a message..."
              value={inputMessage}
              onChange={(e) => setInputMessage(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button variant="contained" color="primary" onClick={sendMessage}>
                      Send
                    </Button>
                  </InputAdornment>
                )
              }}
            />
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default ChatUI;
