import {
  Button,
  Card,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CustomPagination from 'ui-component/CustomPagination';
import SearchComponent from 'ui-component/SearchComponent';
import { getKYCUsersList } from 'store/KYCUsers/actions';
import moment from 'moment';

const KYCUsersList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const [userStatus, setUserStatus] = useState('A');
  const [strSearch, setStrSearch] = useState('');
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(30);
  // useEffect(() => {
  //   dispatch(getUsersList({ chrStatus: userStatus }));
  // }, []);
  const { usersKYCList, usersKYCCount } = useSelector((state) => state?.usersKYC);
  const handleChange = (event, newAlignment) => {
    setUserStatus(newAlignment);
  };
  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
  useEffect(() => {
    dispatch(getKYCUsersList({ chrStatus: 'N', strKYCStatus: userStatus, page: page, limit: limit, strSearch: strSearch }));
  }, [userStatus, page, limit, strSearch]);
  function groupUserssByDate(users) {
    const groupedUsers = {};
    users.forEach((user) => {
      const date = moment(user.strCreatedTime).format('MMMM Do YYYY');
      if (!groupedUsers[date]) {
        groupedUsers[date] = [];
      }
      groupedUsers[date].push(user);
    });
    return groupedUsers;
  }
  const groupedUsers = groupUserssByDate(usersKYCList);
  const handleClickUser = (id) => {
    navigate(`/kycstatus/userdetailed/${id}`);
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <Grid container>
          <ToggleButtonGroup color="primary" value={userStatus} exclusive onChange={handleChange} aria-label="Platform">
            <ToggleButton value="A">Approved</ToggleButton>
            <ToggleButton value="NA">Pending</ToggleButton>
            <ToggleButton value="R">Rejected</ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </Grid>
      <Grid item xs={12} md={12}>
        <SearchComponent searchValue={strSearch} setSearchValue={setStrSearch} />
      </Grid>
      {matchDownSM && (
        <Grid item xs={12} md={12}>
          <Grid container spacing={2}>
            {/* {usersKYCList?.map((user, i) => (
              <Grid item xs={12} key={i}>
                <Card onClick={() => handleClickUser(user?._id)}>
                  <Grid container p={2}>
                    <Grid item xs={10}>
                      <Typography>Name : {user?.strFullName}</Typography>
                      <Typography>Username : {user?.strName}</Typography>
                      <Typography>Email : {user?.strEmail}</Typography>
                    </Grid>
                    <Grid item xs={2} display={'flex'} alignItems={'center'}>
                      <IconButton>
                        <ArrowForwardIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            ))} */}
            {Object.keys(groupedUsers).map((date, i) => (
              <Grid item xs={12} key={i}>
                <Typography variant="h6" style={{ color: 'green', marginBottom: '3px' }}>
                  {date}
                </Typography>
                {groupedUsers[date]?.map((user, j) => (
                  <Card key={j} onClick={() => handleClickUser(user._id)} sx={{ marginTop: '16px' }}>
                    <Grid container p={2}>
                      <Grid item xs={10}>
                        <Typography>Name : {user?.strFullName}</Typography>
                        <Typography>Username : {user?.strName}</Typography>
                        <Typography>Email : {user?.strEmail}</Typography>
                      </Grid>
                      <Grid item xs={2} display={'flex'} alignItems={'center'}>
                        <IconButton>
                          <ArrowForwardIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Card>
                ))}
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}
      {!matchDownSM && (
        <Grid item xs={12} md={12}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Username</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {usersKYCList?.map((user, i) => (
                  <TableRow key={i}>
                    <TableCell>{user?.strFullName}</TableCell>
                    <TableCell>{user?.strName}</TableCell>
                    <TableCell>{user?.strEmail}</TableCell>
                    <TableCell>
                      <Button onClick={() => handleClickUser(user?._id)} variant="contained">
                        View
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      )}
      <Grid item xs={12} md={12}>
        <CustomPagination count={usersKYCCount} page={page} rowsPerPage={limit} setRowsPerPage={setLimit} onPageChange={setPage} />
      </Grid>
    </Grid>
  );
};

export default KYCUsersList;
