import { GET_PACKAGES } from './constants';

const initialState = {
  activePackages: []
};

export default function packages(state = initialState, { type, payload }) {
  switch (type) {
    case GET_PACKAGES:
      return {
        ...state,
        activePackages: payload
      };
    default:
      return state;
  }
}
