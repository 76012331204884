import { Card, Grid, Typography } from '@mui/material';
import React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getZeroInvestment } from 'store/investments/actions';

const ZeroInvestmentList = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getZeroInvestment());
  }, []);
  const { zeroInvestmentUsers } = useSelector((state) => state?.investments);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card sx={{ padding: '16px' }}>
          <Typography variant="h3">Zero Investment Users</Typography>
          <Typography variant="h5">Count : {zeroInvestmentUsers?.length}</Typography>
        </Card>
      </Grid>
      <Grid item xs={12} md={12}>
        {zeroInvestmentUsers?.map((user) => (
          <Card key={user?._id} sx={{ marginTop: '16px', padding: '16px' }}>
            <Grid item>
              <Typography>Name : {user.strFullName}</Typography>
            </Grid>
          </Card>
        ))}
      </Grid>
    </Grid>
  );
};

export default ZeroInvestmentList;
