import { Button, Grid } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import CustomDrawer from 'ui-component/drawer/CustomDrawer';
import AddPlan from './AddPlan';
import ListPlan from './ListPlan';

const Plans = () => {
  const [openAddDrawer, setOpenAddDrawer] = useState(false);
  const handleOpenAddDrawer = () => {
    setOpenAddDrawer(true);
  };
  const handleCloseAddDrawer = () => {
    setOpenAddDrawer(false);
  };
  const handleClickAddPlan = () => {
    handleOpenAddDrawer();
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <Grid container display={'flex'} justifyContent={'right'}>
          <Button variant="contained" onClick={handleClickAddPlan}>
            Add Package
          </Button>
          <CustomDrawer open={openAddDrawer} onClose={handleCloseAddDrawer} title={'Add Plan'}>
            <AddPlan onClose={handleCloseAddDrawer} />
          </CustomDrawer>
        </Grid>
      </Grid>
      <Grid item xs={12} md={12}>
        <ListPlan />
      </Grid>
    </Grid>
  );
};

export default Plans;
