import { Card, Grid, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getTopupLogs } from 'store/investments/actions';
import { CHANGE_TOPUP_CHRSTATUSINV } from 'store/investments/constants';
import moment from 'moment';

const TopupInvestmentLogs = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { topUpInvestments, topUpChrStatus } = useSelector((state) => state?.investments);

  const handleChange = (event, newAlignment) => {
    // setInvestmentStatus(newAlignment);
    dispatch({
      type: CHANGE_TOPUP_CHRSTATUSINV,
      payload: newAlignment
    });
  };

  useEffect(() => {
    dispatch(getTopupLogs({ chrStatus: topUpChrStatus }));
  }, [topUpChrStatus]);
  function groupInvestmentsByDate(investments) {
    const groupedInvestments = {};
    investments?.forEach((investment) => {
      const date = moment(investment.strCreatedTime).format('MMMM Do YYYY');
      if (!groupedInvestments[date]) {
        groupedInvestments[date] = [];
      }
      groupedInvestments[date].push(investment);
    });
    return groupedInvestments;
  }
  const groupedInvestments = groupInvestmentsByDate(topUpInvestments);
  console.log('topUpInvestments', groupedInvestments);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card sx={{ padding: '16px' }}>
          <Typography variant="h3">TopUp Investment Logs</Typography>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <ToggleButtonGroup color="primary" value={topUpChrStatus} exclusive onChange={handleChange} aria-label="Platform">
            <ToggleButton value="P">Pending</ToggleButton>
            <ToggleButton value="N">Approved</ToggleButton>
            <ToggleButton value="S">Rejected</ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </Grid>
      <Grid item xs={12} md={12}>
        {/* <Grid container spacing={2}>
            {allInvestments?.map((investment, i) => (
              <Grid item xs={12} key={i}>
                <Card onClick={() => handleClickInvestment(investment?._id)}>
                  <Grid container p={2}>
                    <Grid item xs={10}>
                      <Typography>Name : {investment?.objUser[0]?.strFullName}</Typography>
                      <Typography>Amount : {investment?.intTotalAmt}</Typography>
                      <Typography>Plan : {investment?.objPackage[0]?.strName}</Typography>
                      <Typography>Applied time : {moment(investment?.strCreatedTime).format(' MMMM Do YYYY, h:mm a')}</Typography>
                    </Grid>
                    <Grid item xs={2} display={'flex'} alignItems={'center'}>
                      <IconButton>
                        <ArrowForwardIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            ))}
          </Grid> */}
        <Grid container spacing={2}>
          {Object.keys(groupedInvestments).map((date, i) => (
            <Grid item xs={12} key={i}>
              <Typography variant="h6" style={{ color: 'green', marginBottom: '3px' }}>
                {date}
              </Typography>
              {groupedInvestments[date]?.map((investment, j) => (
                <Card key={j} sx={{ marginTop: '16px' }}>
                  <Grid container p={2}>
                    <Grid item xs={10}>
                      <Typography>Name : {investment?.objUser[0]?.strFullName}</Typography>
                      <Typography>Amount : {investment?.intAmt}</Typography>
                      <Typography>Applied time : {moment(investment?.strCreatedTime).format(' MMMM Do YYYY, h:mm a')}</Typography>
                    </Grid>
                    <Grid item xs={2} display={'flex'} alignItems={'center'}>
                      {/* <IconButton>
                          <ArrowForwardIcon />
                        </IconButton> */}
                    </Grid>
                  </Grid>
                </Card>
              ))}
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TopupInvestmentLogs;
