import PropTypes from 'prop-types';

// material-ui
import { useTheme, styled } from '@mui/material/styles';
import { Avatar, Box, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import TotalIncomeCard from 'ui-component/cards/Skeleton/TotalIncomeCard';

// assets
import StorefrontTwoToneIcon from '@mui/icons-material/StorefrontTwoTone';
import { useDispatch, useSelector } from 'react-redux';
import { getMileStones } from 'store/milestone/actions';
import { useState } from 'react';
import { useEffect } from 'react';
import api from 'utils/api';

// styles
const CardWrapper = styled(MainCard)(({ theme }) => ({
  overflow: 'hidden',
  position: 'relative',
  '&:after': {
    content: '""',
    position: 'absolute',
    width: 210,
    height: 210,
    background: `linear-gradient(210.04deg, ${theme.palette.warning.dark} -50.94%, rgba(144, 202, 249, 0) 83.49%)`,
    borderRadius: '50%',
    top: -30,
    right: -180
  },
  '&:before': {
    content: '""',
    position: 'absolute',
    width: 210,
    height: 210,
    background: `linear-gradient(140.9deg, ${theme.palette.warning.dark} -14.02%, rgba(144, 202, 249, 0) 70.50%)`,
    borderRadius: '50%',
    top: -160,
    right: -130
  }
}));

// ==============================|| DASHBOARD - TOTAL INCOME LIGHT CARD ||============================== //

const TotalIncomeLightCard = ({ isLoading, investmentAmount, userRefCode }) => {
  console.log('investmentAmount', investmentAmount);
  const theme = useTheme();
  const dispatch = useDispatch();
  const [mileStonereached, setMileStonereached] = useState(0);
  const [maxValue, setMaxValue] = useState(0);

  const [directUsers, setDirectUsers] = useState([]);
  useEffect(() => {
    dispatch(getMileStones());
  }, []);
  const { mileStoneList } = useSelector((state) => state?.mileStone);
  useEffect(() => {
    // Simulate initial API call for the root users
    async function fetchRootUsers() {
      try {
        const response = await api.post('/get_ref_legs', {
          strRefCode: userRefCode
        });

        if (response.data.success) {
          setDirectUsers(response.data.arrList);
        }
      } catch (error) {
        console.error('Error fetching root users:', error);
      }
    }

    fetchRootUsers();
  }, [userRefCode]);
  console.log('directUsers', directUsers);
  useEffect(() => {
    let totalCapitalAmount = 0;

    directUsers.forEach((user) => {
      let userTotal = user.arrInvestMents.reduce((acc, investment) => {
        return acc + investment.intCapitalAmt;
      }, 0);
      // Add individual user's intCapitalAmt to the total
      totalCapitalAmount += userTotal;
    });

    setMileStonereached(totalCapitalAmount);
  }, [directUsers]);
  useEffect(() => {
    let points = [];
    if (mileStoneList?.length > 0 && mileStonereached && investmentAmount && directUsers.length) {
      let maximumValue = 0;
      let currentMilestone;
      let nextMileStone;
      for (let i = 0; i < mileStoneList?.length; i++) {
        // points.push({
        //   value: mileStoneList[i].intAmount,
        //   label: `${mileStoneList[i].intAmount}$`
        // });
        // if (i > 0 && mileStoneList[i]?.intAmount > maximumValue) {
        //   maximumValue = mileStoneList[i]?.intAmount;
        // }
        if (mileStonereached >= mileStoneList[i].intAmount) {
          if (investmentAmount >= mileStoneList[i].intMinInvestment && directUsers.length >= mileStoneList[i].intMinDirectReferral) {
            maximumValue = mileStoneList[i].intSalaryAmount;
            currentMilestone = mileStoneList[i];
            nextMileStone = mileStoneList[i + 1] ? mileStoneList[i + 1] : mileStoneList[i];
          }
          // break;
        }
      }

      setMaxValue(maximumValue);
      // if (currentMilestone && nextMileStone) {
      //   fireworksFunc(currentMilestone, nextMileStone);
      // }
    } else {
      points.push({ value: 0, label: 'No milestones available' });
    }
  }, [mileStoneList, mileStonereached, investmentAmount, directUsers]);
  return (
    <>
      {isLoading ? (
        <TotalIncomeCard />
      ) : (
        <CardWrapper border={false} content={false}>
          <Box sx={{ p: 2 }}>
            <List sx={{ py: 0 }}>
              <ListItem alignItems="center" disableGutters sx={{ py: 0 }}>
                <ListItemAvatar>
                  <Avatar
                    variant="rounded"
                    sx={{
                      ...theme.typography.commonAvatar,
                      ...theme.typography.largeAvatar,
                      backgroundColor: theme.palette.warning.light,
                      color: theme.palette.warning.dark
                    }}
                  >
                    <StorefrontTwoToneIcon fontSize="inherit" />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  sx={{
                    py: 0,
                    mt: 0.45,
                    mb: 0.45
                  }}
                  primary={<Typography variant="h4">${maxValue ? maxValue : 0}</Typography>}
                  secondary={
                    <Typography
                      variant="subtitle2"
                      sx={{
                        color: theme.palette.grey[500],
                        mt: 0.5
                      }}
                    >
                      Executive Salary
                    </Typography>
                  }
                />
              </ListItem>
            </List>
          </Box>
        </CardWrapper>
      )}
    </>
  );
};

TotalIncomeLightCard.propTypes = {
  isLoading: PropTypes.bool
};

export default TotalIncomeLightCard;
