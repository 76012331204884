import api from 'utils/api';
import { GET_WALLET_DETAILS } from './constants';

export const getWalletDetails = (body) => async (dispatch) => {
  try {
    const { data } = await api.post('/get_wallet_details', body);
    dispatch({ type: GET_WALLET_DETAILS, payload: data });
  } catch (error) {
    console.log(error);
  }
};
