import {
  Alert,
  Button,
  Card,
  CardMedia,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Snackbar,
  Typography
} from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { approveWithdrawal } from 'store/withdraw/actions';
import clipboardCopy from 'clipboard-copy';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import YesOrNoModal from 'ui-component/modals/YesOrNoModal';
import { useState } from 'react';

const DetailedWithdrawals = () => {
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [objId, setObjId] = useState('');
  const handleOpenModal = () => {
    setConfirmationModalOpen(true);
  };
  const handleCloseModal = () => {
    setConfirmationModalOpen(false);
  };
  const dispatch = useDispatch();
  const params = useParams();
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const { withdrawalList } = useSelector((state) => state?.withdraw);
  const currentWithdrawal = withdrawalList?.find((withdraw) => withdraw?._id === params.withdrawalId);
  const handleReject = async (id, rejectMsg) => {
    await dispatch(approveWithdrawal({ _id: id, chrStatus: 'R', rejectMsg: rejectMsg }));
    navigate('/withdraw');
  };
  const handleClickReject = async (id) => {
    setObjId(id);
    handleOpenModal();
  };
  const handleClickApprove = async (id) => {
    await dispatch(approveWithdrawal({ _id: id }));
    navigate('/withdraw');
  };
  const copyToClipboard = (text) => {
    clipboardCopy(text);
    setOpen(true);
  };
  const handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <Card sx={{ padding: '16px' }}>
          <Typography variant="h3">Applied Withdrawals</Typography>
        </Card>
      </Grid>
      <Grid item xs={12} md={12}>
        <Card sx={{ padding: '16px' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Typography>Full Name : {currentWithdrawal?.objUser?.strFullName}</Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography>Email Id : {currentWithdrawal?.objUser?.strEmail}</Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography>Mobile No : {currentWithdrawal?.objUser?.strMobileNo}</Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography>Withdrawal From : {currentWithdrawal?.strType}</Typography>
            </Grid>
            {currentWithdrawal?.strType === 'capital' && (
              <Grid item xs={12} md={12}>
                <Typography>Plan : {currentWithdrawal?.objPackage[0]?.strName}</Typography>
              </Grid>
            )}
            <Grid item xs={12} md={12}>
              <Typography>Requested Amount : {currentWithdrawal?.intTotalDeductionAmt}</Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography>Service Fee : {currentWithdrawal?.intServiceFeeAmt}</Typography>
            </Grid>
            {currentWithdrawal?.strType === 'capital' && (
              <Grid item xs={12} md={12}>
                <Typography>90 days below withdrawal amount : {currentWithdrawal?.intCapitalreturnWithin90DaysAmt}</Typography>
              </Grid>
            )}
            <Grid item xs={12} md={12}>
              <Typography>Payable Amount : {currentWithdrawal?.intPayableAmt}</Typography>
            </Grid>
            {/* <Grid item xs={12} md={12}>
              <Typography>Wallet Address : {currentWithdrawal?.strReturnAddress}</Typography>
            </Grid> */}
            <Grid item xs={12} md={12}>
              <FormControl fullWidth>
                <OutlinedInput
                  id="outlined-adornment-password-register"
                  value={currentWithdrawal?.strReturnAddress}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => copyToClipboard(currentWithdrawal?.strReturnAddress)}
                        edge="end"
                        size="large"
                      >
                        <ContentCopyIcon style={{ color: 'black' }} />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              <Snackbar
                open={open}
                autoHideDuration={3000}
                onClose={handleCloseSnack}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              >
                <Alert variant="filled" onClose={handleCloseSnack} severity="success" sx={{ width: '100%' }}>
                  Copied.
                </Alert>
              </Snackbar>
            </Grid>
            {currentWithdrawal?.strReturnAddressUrl && (
              <Grid item xs={12} md={12}>
                <Grid>
                  <Grid item xs={12} md={12}>
                    <Typography>Withdrawal QR : </Typography>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <img src={currentWithdrawal?.strReturnAddressUrl} alt="" style={{ maxWidth: '100%', maxHeight: '100%' }} />
                    {/* <CardMedia sx={{ height: 240 }} image={currentWithdrawal?.strReturnAddressUrl} title="green iguana" /> */}
                  </Grid>
                </Grid>
              </Grid>
            )}

            {/* {currentWithdrawal?.strType === 'wallet' && (
              <Grid item xs={12} md={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <Typography>Email Id : {currentWithdrawal?.objUser[0]?.strEmail}</Typography>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Typography>Mobile No : {currentWithdrawal?.objUser[0]?.strMobileNo}</Typography>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Typography>Withdrawal From : {currentWithdrawal?.strType}</Typography>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Typography>Requested Amount : {currentWithdrawal?.intTotalDeductionAmt}</Typography>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Typography>Service Fee : {currentWithdrawal?.intServiceFeeAmt}</Typography>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Typography>Payable Amount : {currentWithdrawal?.intPayableAmt}</Typography>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Typography>Wallet Address : {currentWithdrawal?.strReturnAddress}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            )} */}

            <Grid item xs={12} md={12}>
              {currentWithdrawal?.chrStatus === 'P' && (
                <>
                  <Button variant="contained" onClick={() => handleClickApprove(currentWithdrawal?._id)}>
                    Approve
                  </Button>
                  <Button variant="outlined" onClick={() => handleClickReject(currentWithdrawal?._id)}>
                    Reject
                  </Button>
                </>
              )}
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <YesOrNoModal
        isOpen={confirmationModalOpen}
        onClose={handleCloseModal}
        title={'Are you sure ?'}
        func={handleReject}
        objectId={objId}
      />
    </Grid>
  );
};

export default DetailedWithdrawals;
