import api from 'utils/api';
import { GET_CHAT_HISTORY, GET_MESSAGE_HISTORY } from './constants';

export const getChatHistory = () => async (dispatch) => {
  try {
    const { data } = await api.post('/get_chat_history');
    dispatch({
      type: GET_CHAT_HISTORY,
      payload: data?.arrList
    });
  } catch (error) {
    console.log(error);
  }
};
export const getMessageHistory = (body) => async (dispatch) => {
  try {
    const { data } = await api.post('/get_messages', body);
    dispatch({
      type: GET_MESSAGE_HISTORY,
      payload: data?.arrList
    });
  } catch (error) {
    console.log(error);
  }
};
