import { CHANGE_WITHDRAWAL_STATUS, CHANGE_WITHDRAW_LIST_PAGE, GET_WITHDRAWALS } from './constants';

const initialState = {
  withdrawalList: null,
  withdrawalListCount: null,
  chrStatusWithdrawal: 'P',
  page: 1
};

export default function withdraw(state = initialState, { type, payload }) {
  switch (type) {
    case GET_WITHDRAWALS:
      return {
        ...state,
        withdrawalList: payload?.arrList,
        withdrawalListCount: payload?.count
      };
    case CHANGE_WITHDRAWAL_STATUS:
      return {
        ...state,
        chrStatusWithdrawal: payload
      };
    case CHANGE_WITHDRAW_LIST_PAGE:
      return {
        ...state,
        page: payload
      };
    default:
      return state;
  }
}
