import { Button, Card, CardContent, Grid, Typography } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import CustomDrawer from 'ui-component/drawer/CustomDrawer';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { deleteTeampool, getTeamPool } from 'store/teampool/actions';
import NewTeamPoolForm from './NewTeamPoolForm';
import EditTeamPool from './EditTeamPool';
import { useNavigate } from 'react-router-dom';

const TeamPoolMain = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openEdit, setOpenEdit] = useState(false);
  const [editValues, setEditValues] = useState(null);
  const handleOpenEdit = () => {
    setOpenEdit(true);
  };
  const handleCloseEdit = () => {
    setOpenEdit(false);
  };
  const [openDrawer, setOpenDrawer] = useState(false);
  const handleOpen = () => {
    setOpenDrawer(true);
  };
  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };
  const handleClickNew = () => {
    handleOpen();
  };
  useEffect(() => {
    dispatch(getTeamPool());
  }, []);
  const { teampoolList } = useSelector((state) => state?.teampool);
  console.log(teampoolList);
  const handleClickDelete = (id) => {
    const data = {
      _id: id,
      strType: 'infinity',
      type: 'commonDelete'
    };
    dispatch(deleteTeampool(data));
  };
  const handleClickEdit = (values) => {
    setEditValues(values);
    handleOpenEdit();
  };
  const handleClickUsers = () => {
    navigate('/infinity/achievedusers');
  };
  const handleClickSalary = () => {
    navigate('/infinity/salarylogs');
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <Card sx={{ p: '16px' }}>
          <Typography variant="h3">Team Pool</Typography>
        </Card>
      </Grid>
      <Grid item xs={12} md={12}>
        <Button variant="contained" onClick={handleClickNew}>
          {' '}
          + New Infinity
        </Button>
      </Grid>
      <Grid item xs={12} md={12}>
        <Button variant="contained" onClick={handleClickUsers}>
          Infinity Achieved users
        </Button>
      </Grid>
      <Grid item xs={12} md={12}>
        <Button variant="contained" onClick={handleClickSalary}>
          Infinity Salary Logs
        </Button>
      </Grid>
      <Grid item xs={12} md={12}>
        <Grid container>
          {teampoolList?.length > 0 &&
            teampoolList?.map((data) => (
              <Grid item xs={12} md={4} key={data?._id}>
                <Card variant="outlined">
                  <CardContent>
                    <Typography variant="h6" component="div">
                      {data.strName}
                    </Typography>
                    <Typography color="textSecondary">Amount: {data.intAmount}</Typography>
                    <Typography color="textSecondary">Minimum Invesment Amount: {data.intMinInvestment}</Typography>
                    <Typography color="textSecondary">Minimum Direct Referral Count: {data.intMinDirectReferral}</Typography>
                    <Typography color="textSecondary">Percentage: {data.intAimAmtPerc}</Typography>
                    {/* <Typography color="textSecondary">Referral Amounts:</Typography> */}
                    {/* <ul>
                      {data?.arrReferalAmtPerc?.map((amount, index) => (
                        <li key={index}>{amount}%</li>
                      ))}
                    </ul> */}
                    <Button variant="contained" color="success" onClick={() => handleClickEdit(data)}>
                      Edit
                    </Button>
                    <Button variant="contained" color="error" onClick={() => handleClickDelete(data?._id)}>
                      Delete
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
            ))}
        </Grid>
      </Grid>
      <CustomDrawer open={openDrawer} onClose={handleCloseDrawer} title={'Add Milestone'}>
        <NewTeamPoolForm onClose={handleCloseDrawer} />
      </CustomDrawer>
      <CustomDrawer open={openEdit} onClose={handleCloseEdit} title={'Edit Milestone'}>
        <EditTeamPool milestoneDetails={editValues} onClose={handleCloseEdit} />
      </CustomDrawer>
    </Grid>
  );
};

export default TeamPoolMain;
