import { Button, Grid, MenuItem, Select, TextField, Typography } from '@mui/material'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getUsersList } from 'store/users/actions'
import { getWalletDetails } from 'store/wallet/actions'
import api from 'utils/api'

const ITEM_HEIGHT = 60;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function ManualWithdrawals() {
    const [errMsg, setErrMsg] = useState("")
    const [selectedUser, setSelectedUser] = useState(null)
    const [newAmt, setNewAmt] = useState(0)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getUsersList({ chrStatus: "N", page: 1, limit: 100, strSearch: "" }))
    }, [])

    useEffect(() => {
        if (selectedUser && selectedUser?._id) {
            dispatch(getWalletDetails({ _id: selectedUser?._id }))
        }
    }, [selectedUser])
    const { usersList, usersCount, page } = useSelector((state) => state?.users);
    const { walletDetails } = useSelector((state) => state?.wallet);
    console.log("usersList", usersList)
    console.log("walletDetails", walletDetails)
    const currentInvestment = walletDetails?.arrCapitals[0]?.intAmt
    const totalWithdrawed = walletDetails?.arrWalletsTotals?.find((walletsTotal) => walletsTotal.strIncomeType === 'RETURNS')?.intAmt ? walletDetails?.arrWalletsTotals?.find((walletsTotal) => walletsTotal.strIncomeType === 'RETURNS')?.intAmt : 0
    const handleClickSubmit = async () => {
        if (!newAmt || newAmt > currentInvestment + totalWithdrawed) {
            setErrMsg("Type a valid number")
            return
        }
        setErrMsg("")
        let body = {
            strUserId: selectedUser?._id,
            strReturnAddress: "created by admin",
            intTotalDeductionAmt: newAmt,
            strReturnAddressBase64: "created By Admin",
            strType: "wallet",
            intServiceFeeAmt: 0,
            intPayableAmt: newAmt,
            strTransactionPassword: "createdByAdmin"
        };
        console.log("body", body)
        try {
            await createWithdrawalReq(body)
            setSelectedUser()
            setNewAmt()

        } catch (error) {
            console.log("Error", error)
        }

    }
    const createWithdrawalReq = async (body) => {
        const { data } = await api.post('/create_return_by_admin', body);
        return data;
    };
    return (
        <div>
            <Grid container>
                <Grid item xs={4}>
                    <Typography>Select User</Typography>
                    <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={selectedUser}
                        label="Filter"
                        onChange={(e) => setSelectedUser(e.target.value)}
                        fullWidth
                        MenuProps={MenuProps}
                    >
                        {usersList?.map((item, i) => (
                            <MenuItem value={item} key={i}>{item?.strName}</MenuItem>
                        ))}
                    </Select>
                    <Typography>Selected User User Name : {selectedUser?.strName}</Typography>
                    <Typography>Selected User Name : {selectedUser?.strFullName}</Typography>
                    <Typography>Selected User Email :{selectedUser?.strEmail}</Typography>

                    <Typography sx={{ mt: 2 }}>Available Amount :{currentInvestment + totalWithdrawed < 1 ? "No Amount" : currentInvestment + totalWithdrawed} </Typography>

                    <Typography sx={{ mt: 2 }}>Enter Amount  </Typography>
                    <TextField value={newAmt} type='number' onChange={(e) => setNewAmt(e.target.value)} fullWidth />

                    <Button onClick={handleClickSubmit} variant='contained' >Submit</Button>
                    {errMsg && <Typography color={"error"}>{errMsg}</Typography>}

                </Grid>
            </Grid>
        </div>
    )
}
