import api from 'utils/api';
import { GET_USERS_LIST_KYC } from './constants';

export const getKYCUsersList = (body) => async (dispatch) => {
  try {
    const { data } = await api.post('/get_user_list', body);
    dispatch({
      type: GET_USERS_LIST_KYC,
      payload: data
    });
  } catch (error) {
    console.log(error);
  }
};
export const approveKYCUser = (body) => async (dispatch) => {
  try {
    const { data } = await api.post('/approve_user', body);
    dispatch(getKYCUsersList({ chrStatus: 'N', strKYCStatus: body.strKYCStatus }));
  } catch (error) {
    console.log(error);
  }
};
