import { Card, Grid, IconButton, Typography } from '@mui/material';
import React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUsersList } from 'store/users/actions';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate } from 'react-router-dom';
import CustomPagination from 'ui-component/CustomPagination';
import SearchComponent from 'ui-component/SearchComponent';
import { useState } from 'react';

const PickNewChat = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [strSearch, setStrSearch] = useState('');
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(30);
  useEffect(() => {
    dispatch(getUsersList({ chrStatus: 'N', page: page, limit: limit, strSearch: strSearch }));
  }, [page, limit, strSearch]);
  const { usersList, usersCount } = useSelector((state) => state?.users);
  const handleClickUser = (id) => {
    navigate(`/chat/chatwindow/${id}`);
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <Card sx={{ padding: '16px' }}>
          <Typography variant="h3">New Chat</Typography>
        </Card>
      </Grid>
      <Grid item xs={12} md={12}>
        <SearchComponent searchValue={strSearch} setSearchValue={setStrSearch} />
      </Grid>
      <Grid item xs={12} md={12}>
        <Grid container>
          <Grid item md={6} xs={12}>
            <Grid container spacing={2}>
              {usersList?.map((user, i) => (
                <Grid item xs={12} key={i}>
                  <Card onClick={() => handleClickUser(user?._id)}>
                    <Grid container p={2}>
                      <Grid item xs={10}>
                        <Typography>Name : {user?.strFullName}</Typography>
                        <Typography>Username : {user?.strName}</Typography>
                        <Typography>Email : {user?.strEmail}</Typography>
                      </Grid>
                      <Grid item xs={2} display={'flex'} alignItems={'center'}>
                        <IconButton>
                          <ArrowForwardIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item md={6} xs={12}></Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={12}>
        <CustomPagination count={usersCount} page={page} rowsPerPage={limit} setRowsPerPage={setLimit} onPageChange={setPage} />
      </Grid>
    </Grid>
  );
};

export default PickNewChat;
