import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import React from 'react';
// import { useDispatch } from 'react-redux';
import MainCard from 'ui-component/cards/MainCard';
import AnimateButton from 'ui-component/extended/AnimateButton';
import * as Yup from 'yup';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useState } from 'react';

const UserProfile = () => {
  //   const dispatch = useDispatch();
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));

  const [showPassword, setShowPassword] = useState(false);
  const [transShowPass, setTransShowPass] = useState(false);
  //functions
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleClickTransShowPassword = () => {
    setTransShowPass(!transShowPass);
  };

  const formik = useFormik({
    initialValues: {
      strProfileBase64: '',
      strIDBase64: '',
      strTransProfBase64: '',
      strName: '',
      strFullName: '',
      strMobileNo: '',
      strEmail: '',
      strPassword: '',
      confirmPassword: '',
      strTransPassword: '',
      confirmTransPassword: '',
      strRefCodeBy: '',
      strPackageId: '',
      intCapitalAmt: '',
      intTotalAmt: ''
      // submit: null
    },
    validationSchema: Yup.object().shape({
      strName: Yup.string().required('Username is required').nullable(),
      strFullName: Yup.string().required('Full Name is required').nullable(),
      strIDBase64: Yup.string().required('ID proof is required').nullable(),
      strMobileNo: Yup.string().matches('[0-9]{10}', 'Provide a valid phone number').max(10).min(10).required('Phone number is required'),
      strEmail: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
      strPassword: Yup.string().max(255).required('Password is required'),
      confirmPassword: Yup.string()
        .required('Please retype your password.')
        .oneOf([Yup.ref('strPassword'), null], 'Passwords must match'),
      strTransPassword: Yup.string().max(255).required('Password is required'),
      confirmTransPassword: Yup.string()
        .required('Please retype your password.')
        .oneOf([Yup.ref('strTransPassword'), null], 'Passwords must match')
    }),
    onSubmit: async (values, { setSubmitting }) => {
      values.strType = 'USER';
      if (values.strIDBase64) {
        values.strIDBase64 = values.strIDBase64.split(',')[1];
      }
      if (values.strProfileBase64) {
        values.strProfileBase64 = values.strProfileBase64.split(',')[1];
      }
      if (values.strTransProfBase64) {
        values.strTransProfBase64 = values.strTransProfBase64.split(',')[1];
      }
      console.log(values);
      setSubmitting(true);
      //   const res = await dispatch(createNewUser(values));
      //   if (res === 200) {
      //     console.log('success');
      //     setSubmitting(true);
      //   } else {
      //     console.log('fals');
      //   }
    }
  });
  const { errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values } = formik;
  return (
    <MainCard title="User Profile">
      <FormikProvider value={formik}>
        <Form onSubmit={handleSubmit}>
          <Grid container spacing={matchDownSM ? 1.5 : 2}>
            <Grid item xs={12} md={12}>
              <Grid container spacing={matchDownSM ? 1.5 : 2}>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <TextField
                      fullWidth
                      size={matchDownSM ? 'small' : 'normal'}
                      label="Username"
                      name="strName"
                      type="text"
                      onChange={handleChange}
                      error={Boolean(touched.strName && errors.strName)}
                    />
                    {touched.strName && errors.strName && (
                      <FormHelperText error id="standard-weight-helper-text-password-register">
                        {errors.strName}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <TextField
                      fullWidth
                      size={matchDownSM ? 'small' : 'normal'}
                      label="Full Name"
                      name="strFullName"
                      type="text"
                      onChange={handleChange}
                      error={Boolean(touched.strFullName && errors.strFullName)}
                    />
                    {touched.strFullName && errors.strFullName && (
                      <FormHelperText error id="standard-weight-helper-text-password-register">
                        {errors.strFullName}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12}>
              <FormControl fullWidth>
                <TextField
                  fullWidth
                  size={matchDownSM ? 'small' : 'normal'}
                  label="Phone Number"
                  name="strMobileNo"
                  type="text"
                  onChange={handleChange}
                  error={Boolean(touched.strMobileNo && errors.strMobileNo)}
                />
                {touched.strMobileNo && errors.strMobileNo && (
                  <FormHelperText error id="standard-weight-helper-text-password-register">
                    {errors.strMobileNo}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} md={12}>
              <FormControl fullWidth error={Boolean(touched.strEmail && errors.strEmail)}>
                <InputLabel htmlFor="outlined-adornment-email-register">Email Address</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-email-register"
                  type="email"
                  label="Email Address"
                  size={matchDownSM ? 'small' : 'normal'}
                  value={values.strEmail}
                  name="strEmail"
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {touched.strEmail && errors.strEmail && (
                  <FormHelperText error id="standard-weight-helper-text--register">
                    {errors.strEmail}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            {/* <Grid item xs={12} md={12}>
              {profileFileName ? <Typography>{profileFileName}</Typography> : <Typography>Choose Profile Picture</Typography>}
            </Grid>
            <Grid item xs={12} md={12}>
              {profileFileName ? (
                <Button fullWidth variant="contained" onClick={handleClickFileRemove}>
                  Remove
                </Button>
              ) : (
                <Button fullWidth variant="contained" component="label">
                  Upload
                  <input onChange={handleChangeFileProfile} hidden accept="image/*" type="file" />
                </Button>
              )}
              {errors.strProfileBase64 && (
                <FormHelperText error id="standard-weight-helper-text-password-register">
                  {errors.strProfileBase64}
                </FormHelperText>
              )}
            </Grid> */}

            {/* <Grid item xs={12} md={12}>
              {matchDownSM ? (
                <div
                  style={{
                    overflowX: 'scroll'
                  }}
                >
                  <Box style={{ display: 'flex', maxWidth: '200px' }}>
                    {activePackages?.map((packageItem, i) => (
                      <Grid key={packageItem?._id} sx={{ marginRight: '10px' }}>
                        <Paper elevation={4}>
                          <Card
                            onClick={() => handleClickCardMob(packageItem, i)}
                            sx={{
                              height: '100px',
                              width: '200px',
                              backgroundColor: activeIndex === i ? '#111936' : '#D1C4E9',
                              color: activeIndex === i ? '#D1C4E9' : '#111936',
                              padding: '16px'
                            }}
                          >
                            <Grid container>
                              <Grid item xs={12} display={'flex'} justifyContent={'center'}>
                                <Typography sx={{ fontSize: '1.5rem' }}>{packageItem?.strName}</Typography>
                              </Grid>
                              <Grid item xs={12} display={'flex'} justifyContent={'center'}>
                                <Typography sx={{ fontSize: '.9rem' }}>{packageItem?.strPercentageRane}%</Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Typography sx={{ fontSize: '1.1rem' }}>${packageItem?.intDepositeAmount}</Typography>
                              </Grid>
                            </Grid>
                          </Card>
                        </Paper>
                      </Grid>
                    ))}
                  </Box>
                </div>
              ) : (
                <Grid container spacing={1}>
                  {activePackages?.map((packageItem, i) => (
                    <Grid item md={6} key={packageItem?._id}>
                      <Card
                        onClick={() => handleClickCardMob(packageItem, i)}
                        sx={{
                          height: '100px',
                          width: '100%',
                          backgroundColor: activeIndex === i ? '#212946' : '#D1C4E9',
                          color: activeIndex === i ? '#D1C4E9' : '#212946',
                          padding: '16px'
                        }}
                      >
                        <Grid container>
                          <Grid item xs={12} display={'flex'} justifyContent={'center'}>
                            <Typography sx={{ fontSize: '1.5rem' }}>{packageItem?.strName}</Typography>
                          </Grid>
                          <Grid item xs={12} display={'flex'} justifyContent={'center'}>
                            <Typography sx={{ fontSize: '.9rem' }}>{packageItem?.strPercentageRane}%</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography sx={{ fontSize: '1.1rem' }}>${packageItem?.intDepositeAmount}</Typography>
                          </Grid>
                        </Grid>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              )}
            </Grid>
            {selectedPachageData && (
              <Grid item xs={12} md={12}>
                <PackageDetailCard packageData={selectedPachageData} />
              </Grid>
            )} */}

            <Grid item xs={12} md={12}>
              <FormControl fullWidth error={Boolean(touched.strPassword && errors.strPassword)}>
                <InputLabel htmlFor="outlined-adornment-password-register">Password</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password-register"
                  type={showPassword ? 'text' : 'password'}
                  size={matchDownSM ? 'small' : 'normal'}
                  value={values.strPassword}
                  name="strPassword"
                  label="Password"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} edge="end" size="large">
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  inputProps={{}}
                />
                {touched.strPassword && errors.strPassword && (
                  <FormHelperText error id="standard-weight-helper-text-password-register">
                    {errors.strPassword}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12} md={12}>
              <FormControl fullWidth error={Boolean(touched.strTransPassword && errors.strTransPassword)}>
                <InputLabel htmlFor="outlined-adornment-password-register">Transaction Password</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password-register"
                  type={transShowPass ? 'text' : 'password'}
                  size={matchDownSM ? 'small' : 'normal'}
                  value={values.strTransPassword}
                  name="strTransPassword"
                  label="Transaction Password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton aria-label="toggle password visibility" onClick={handleClickTransShowPassword} edge="end" size="large">
                        {transShowPass ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  inputProps={{}}
                />
                {touched.strTransPassword && errors.strTransPassword && (
                  <FormHelperText error id="standard-weight-helper-text-password-register">
                    {errors.strTransPassword}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            {/* <Grid item xs={12} md={12}>
              <FormControl fullWidth>
                <TextField
                  fullWidth
                  label="Referance Code"
                  size={matchDownSM ? 'small' : 'normal'}
                  name="strRefCodeBy"
                  type="text"
                  onChange={handleChange}
                />
              </FormControl>
            </Grid> */}

            <Grid item xs={12} md={12}>
              <Box sx={{ mt: 2 }}>
                <AnimateButton>
                  <Button
                    disableElevation
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    color="secondary"
                  >
                    Update
                  </Button>
                </AnimateButton>
              </Box>
            </Grid>
          </Grid>
        </Form>
      </FormikProvider>
    </MainCard>
  );
};

export default UserProfile;
