import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Alert, Avatar, Box, Button, Card, Grid, Snackbar, Typography } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deletePackage, getPackages } from 'store/packages/actions';
import MainCard from 'ui-component/cards/MainCard';
import CustomDrawer from 'ui-component/drawer/CustomDrawer';
import EditPlan from './EditPlan';

const CardWrapper = styled(MainCard)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.dark,
  color: '#fff',
  overflow: 'hidden',
  position: 'relative',
  '&:after': {
    content: '""',
    position: 'absolute',
    width: 210,
    height: 210,
    background: theme.palette.secondary[800],
    borderRadius: '50%',
    top: -85,
    right: -95,
    [theme.breakpoints.down('sm')]: {
      top: -105,
      right: -140
    }
  },
  '&:before': {
    content: '""',
    position: 'absolute',
    width: 210,
    height: 210,
    background: theme.palette.secondary[800],
    borderRadius: '50%',
    top: -125,
    right: -15,
    opacity: 0.5,
    [theme.breakpoints.down('sm')]: {
      top: -155,
      right: -70
    }
  }
}));

const ListPlan = () => {
  const theme = useTheme();
  const [editPackageData, setEditPackageData] = useState(null);
  const [openEditDrawer, setOpenEditDrawer] = useState(false);
  const [deleteErrMsg, setDeleteErrMsg] = useState('');
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackBar(false);
  };
  const handleOpen = () => {
    setOpenSnackBar(true);
  };
  const handleOpenEditDrawer = () => {
    setOpenEditDrawer(true);
  };
  const handleCloseEditDrawer = () => {
    setOpenEditDrawer(false);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getPackages());
  }, []);
  const { activePackages } = useSelector((state) => state?.packages);
  console.log(activePackages);
  const handleClickDelete = async (id) => {
    const data = {
      _id: id,
      strType: 'package',
      type: 'commonDelete'
    };
    try {
      await dispatch(deletePackage(data));
      setDeleteErrMsg({ severity: 'success', msg: 'Succesfully Deleted' });
      handleOpen();
    } catch (error) {
      console.log(error);
      if (error.response.data.strMessage) {
        setDeleteErrMsg({ severity: 'error', msg: error.response.data.strMessage });
        handleOpen();
      }
    }
  };
  const handleClickEdit = (plan) => {
    setEditPackageData(plan);
    handleOpenEditDrawer();
  };
  return (
    <Grid container>
      <Grid item xs={12} md={12}>
        <Grid container spacing={2}>
          {activePackages &&
            activePackages.map((plan) => (
              <Grid item xs={12} md={3} key={plan._id}>
                <CardWrapper border={false} content={false}>
                  <Box sx={{ p: 2.25 }}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography sx={{ fontSize: '2.125rem', fontWeight: 500, mr: 1, mt: 1.75, mb: 0.75 }}>{plan.strName}</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography sx={{ fontSize: '2.125rem', fontWeight: 500, mr: 1, mt: 1.75, mb: 0.75 }}>
                          ${plan.intDepositeAmount} - ${plan?.intToDepositeAmount}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sx={{ mb: 1.25 }}>
                        <Typography
                          sx={{
                            fontSize: '1rem',
                            fontWeight: 500,
                            color: theme.palette.secondary[200]
                          }}
                        >
                          {plan.strPercentageRane}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sx={{ mb: 1.25 }}>
                        <Typography
                          sx={{
                            fontSize: '1rem',
                            fontWeight: 500,
                            color: theme.palette.secondary[200]
                          }}
                        >
                          Weekly percentage :
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sx={{ mb: 1.25 }}>
                        <Typography
                          sx={{
                            fontSize: '1rem',
                            fontWeight: 500,
                            color: theme.palette.secondary[200]
                          }}
                        >
                          Monday : {plan?.intMonAmt}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: '1rem',
                            fontWeight: 500,
                            color: theme.palette.secondary[200]
                          }}
                        >
                          Tuesday : {plan?.intTueAmt}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: '1rem',
                            fontWeight: 500,
                            color: theme.palette.secondary[200]
                          }}
                        >
                          Wednesday : {plan?.intWebAmt}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: '1rem',
                            fontWeight: 500,
                            color: theme.palette.secondary[200]
                          }}
                        >
                          Thursday : {plan?.intThuAmt}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: '1rem',
                            fontWeight: 500,
                            color: theme.palette.secondary[200]
                          }}
                        >
                          Friday : {plan?.intFriAmt}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <Button variant="outlined" onClick={() => handleClickEdit(plan)}>
                          Edit
                        </Button>
                        <Button sx={{ marginLeft: '12px' }} variant="contained" color="error" onClick={() => handleClickDelete(plan?._id)}>
                          Delete
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </CardWrapper>
              </Grid>
            ))}
        </Grid>
      </Grid>
      <CustomDrawer open={openEditDrawer} onClose={handleCloseEditDrawer} title={'Edit Plan'}>
        <EditPlan packageData={editPackageData} onClose={handleCloseEditDrawer} />
      </CustomDrawer>
      <Snackbar open={openSnackBar} autoHideDuration={3000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert variant="filled" onClose={handleClose} severity={deleteErrMsg.severity} sx={{ width: '100%' }}>
          {deleteErrMsg.msg}
        </Alert>
      </Snackbar>
    </Grid>
  );
};

export default ListPlan;
