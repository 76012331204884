import { GET_MILESTONE_LIST } from './constants';

const initialState = {
  mileStoneList: null
};

export default function mileStone(state = initialState, { type, payload }) {
  switch (type) {
    case GET_MILESTONE_LIST:
      return {
        ...state,
        mileStoneList: payload
      };
    default:
      return state;
  }
}
