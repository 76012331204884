import api from 'utils/api';
import { GET_LEVELS } from './constants';

export const getLevels = () => async (dispatch) => {
  try {
    const { data } = await api.post('/get_levels');
    dispatch({
      type: GET_LEVELS,
      payload: data?.arrList
    });
  } catch (error) {
    console.log(error);
  }
};
export const updateLevel = (order, value) => async (dispatch) => {
  const body = {
    intOrder: order,
    intIncomePercentage: value
  };
  try {
    const { data } = await api.post('/create_levels', body);
    dispatch(getLevels());
  } catch (error) {
    console.log(error);
  }
};
