import { GET_USERS_LIST_KYC } from './constants';

const initialState = {
  usersKYCList: [],
  usersKYCCount: null
};

export default function usersKYC(state = initialState, { type, payload }) {
  switch (type) {
    case GET_USERS_LIST_KYC:
      return {
        ...state,
        usersKYCList: payload?.arrList,
        usersKYCCount: payload?.count
      };
    default:
      return state;
  }
}
