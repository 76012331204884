import {
  Button,
  Card,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUsersTotalInvestment } from 'store/investments/actions';
import { CHANGE__USERS_TOTAL_INVESTMENTS_LIST_PAGE } from 'store/investments/constants';
import PaginationRedux from 'ui-component/PaginationRedux';
import SearchComponent from 'ui-component/SearchComponent';

const UsersTotalInvestmentList = () => {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
  const [strSearch, setStrSearch] = useState('');
  // const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(30);
  const dispatch = useDispatch();
  const { usersTotalInvestment, usersTotalInvestmentPage, usersTotalInvestmentCount } = useSelector((state) => state?.investments);
  useEffect(() => {
    dispatch(getUsersTotalInvestment({ page: usersTotalInvestmentPage, limit: limit, strSearch: strSearch }));
  }, [usersTotalInvestmentPage, limit, strSearch]);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card sx={{ padding: '16px' }}>
          <Typography variant="h3">Users Total Investment</Typography>
        </Card>
      </Grid>
      <Grid item xs={12} md={12}>
        <SearchComponent searchValue={strSearch} setSearchValue={setStrSearch} />
      </Grid>
      <Grid item xs={12} md={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {usersTotalInvestment.map((user, j) => (
              <Card key={j} sx={{ marginTop: '16px' }}>
                <Grid container p={2}>
                  <Grid item xs={10}>
                    <Typography>Name : {user?.strName}</Typography>
                    <Typography>Amount : {user?.investments}</Typography>
                    <Typography>Gross Profit : {user?.profits}</Typography>
                    <Typography>Difference : {user?.investments - user?.profits}</Typography>
                  </Grid>
                </Grid>
              </Card>
            ))}
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        {/* <CustomPagination count={allInvestmentsCount} page={page} rowsPerPage={limit} setRowsPerPage={setLimit} onPageChange={setPage} /> */}
        <PaginationRedux
          count={usersTotalInvestmentCount}
          page={usersTotalInvestmentPage}
          rowsPerPage={limit}
          setRowsPerPage={setLimit}
          type={CHANGE__USERS_TOTAL_INVESTMENTS_LIST_PAGE}
        />
      </Grid>
    </Grid>
  );
};

export default UsersTotalInvestmentList;
