import { Button, FormControl, FormHelperText, Grid, TextField, Typography } from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik/dist';
import React from 'react';
import { useDispatch } from 'react-redux';
import { createPackage } from 'store/packages/actions';
import * as Yup from 'yup';

const AddPlan = ({ onClose }) => {
  const dispatch = useDispatch();
  const validationSchema = Yup.object().shape({
    strName: Yup.string().required('Title is required'),
    intDepositeAmount: Yup.string().required('Amount is required'),
    strPercentageRane: Yup.string().required('Percentage range is required'),
    intMonAmt: Yup.string().required('This is required'),
    intTueAmt: Yup.string().required('This is required'),
    intWebAmt: Yup.string().required('This is required'),
    intThuAmt: Yup.string().required('This is required'),
    intFriAmt: Yup.string().required('This is required'),
    intDescription: Yup.string().required('This is required')
  });
  const formik = useFormik({
    initialValues: {
      strName: '',
      intDepositeAmount: '',
      strPercentageRane: '',
      intMonAmt: '',
      intTueAmt: '',
      intWebAmt: '',
      intThuAmt: '',
      intFriAmt: '',
      intDescription: ''
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      // console.log(values);
      // values.strPercentageRane = values.strPercentageRane + '%';
      await dispatch(createPackage(values));
      onClose();
    }
  });
  const { handleChange, handleSubmit, touched, errors } = formik;
  return (
    <FormikProvider value={formik}>
      <Form onSubmit={handleSubmit}>
        <Grid container sx={{ p: '16px' }} spacing={2}>
          <Grid item xs={12} md={12}>
            <FormControl fullWidth>
              <TextField error={Boolean(touched.strName && errors.strName)} label="Title" name="strName" onChange={handleChange} />
              {touched.strName && errors.strName && <FormHelperText error>{errors.strName}</FormHelperText>}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={12}>
            <FormControl fullWidth>
              <TextField
                type="number"
                error={Boolean(touched.intDepositeAmount && errors.intDepositeAmount)}
                label="Amount"
                name="intDepositeAmount"
                onChange={handleChange}
              />
              {touched.intDepositeAmount && errors.intDepositeAmount && <FormHelperText error>{errors.intDepositeAmount}</FormHelperText>}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={12}>
            <FormControl fullWidth>
              <TextField
                error={Boolean(touched.strPercentageRane && errors.strPercentageRane)}
                label="Percentage Range"
                placeholder="ex:1-5"
                name="strPercentageRane"
                onChange={handleChange}
              />
              {touched.strPercentageRane && errors.strPercentageRane && <FormHelperText error>{errors.strPercentageRane}</FormHelperText>}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography variant="h4">Percentage for Different Days</Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <Grid container>
              <Grid item xs={6} md={6} sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="h5">Monday</Typography>
              </Grid>
              <Grid item xs={6} md={6}>
                <FormControl fullWidth>
                  <TextField
                    type="number"
                    inputProps={{ step: 'any' }}
                    error={Boolean(touched.intMonAmt && errors.intMonAmt)}
                    name="intMonAmt"
                    onChange={handleChange}
                  />
                  {touched.intMonAmt && errors.intMonAmt && <FormHelperText error>{errors.intMonAmt}</FormHelperText>}
                </FormControl>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={12}>
            <Grid container>
              <Grid item xs={6} md={6} sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="h5">Tuesday</Typography>
              </Grid>
              <Grid item xs={6} md={6}>
                <FormControl fullWidth>
                  <TextField
                    type="number"
                    inputProps={{ step: 'any' }}
                    error={Boolean(touched.intTueAmt && errors.intTueAmt)}
                    name="intTueAmt"
                    onChange={handleChange}
                  />
                  {touched.intTueAmt && errors.intTueAmt && <FormHelperText error>{errors.intTueAmt}</FormHelperText>}
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12}>
            <Grid container>
              <Grid item xs={6} md={6} sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="h5">Wednesday</Typography>
              </Grid>
              <Grid item xs={6} md={6}>
                <FormControl fullWidth>
                  <TextField
                    type="number"
                    inputProps={{ step: 'any' }}
                    error={Boolean(touched.intWebAmt && errors.intWebAmt)}
                    name="intWebAmt"
                    onChange={handleChange}
                  />
                  {touched.intWebAmt && errors.intWebAmt && <FormHelperText error>{errors.intWebAmt}</FormHelperText>}
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12}>
            <Grid container>
              <Grid item xs={6} md={6} sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="h5">Thursday</Typography>
              </Grid>
              <Grid item xs={6} md={6}>
                <FormControl fullWidth>
                  <TextField
                    type="number"
                    inputProps={{ step: 'any' }}
                    error={Boolean(touched.intThuAmt && errors.intThuAmt)}
                    name="intThuAmt"
                    onChange={handleChange}
                  />
                  {touched.intThuAmt && errors.intThuAmt && <FormHelperText error>{errors.intThuAmt}</FormHelperText>}
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12}>
            <Grid container>
              <Grid item xs={6} md={6} sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="h5">Friday</Typography>
              </Grid>
              <Grid item xs={6} md={6}>
                <FormControl fullWidth>
                  <TextField
                    type="number"
                    inputProps={{ step: 'any' }}
                    error={Boolean(touched.intFriAmt && errors.intFriAmt)}
                    name="intFriAmt"
                    onChange={handleChange}
                  />
                  {touched.intFriAmt && errors.intFriAmt && <FormHelperText error>{errors.intFriAmt}</FormHelperText>}
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12}>
            <FormControl fullWidth>
              <TextField
                error={Boolean(touched.intDescription && errors.intDescription)}
                label="Description"
                multiline
                rows={5}
                name="intDescription"
                onChange={handleChange}
              />
              {touched.intDescription && errors.intDescription && <FormHelperText error>{errors.intDescription}</FormHelperText>}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={12}>
            <Button fullWidth variant="contained" type="submit">
              Add
            </Button>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
};

export default AddPlan;
