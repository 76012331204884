import api from 'utils/api';
import { GET_FAQ_LIST } from './constants';

export const getFaq = () => async (dispatch) => {
  try {
    const body = {
      strType: 'faq',
      type: 'commonList'
    };
    const { data } = await api.post('/common_api', body);
    dispatch({ type: GET_FAQ_LIST, payload: data?.arrList });
  } catch (error) {
    console.log(error);
  }
};
export const createFaq = (body) => async (dispatch) => {
  const newBody = {
    strType: 'faq',
    type: 'commonCreate',
    objDocument: body
  };
  try {
    const { data } = await api.post('/common_api', newBody);
    await dispatch(getFaq());
  } catch (error) {
    console.log(error);
  }
};
export const deleteFaq = (body) => async (dispatch) => {
  try {
    const { data } = await api.post('/common_api', body);
    await dispatch(getFaq());
  } catch (error) {
    console.log(error);
  }
};
