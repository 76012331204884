import { Button, Grid, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSettings } from 'store/settings/actions';
import { createTeampool } from 'store/teampool/actions';

const NewTeamPoolForm = ({ onClose }) => {
  const dispatch = useDispatch();

  const [name, setName] = useState('');
  const [amount, setAmount] = useState('');
  const [monthlyBenefit, setMonthlyBenefit] = useState('');
  const [referralBenefit, setReferralBenefit] = useState(0);
  const [salaryPerc, setSalaryPerc] = useState('');
  const [textFields, setTextFields] = useState(Array.from({ length: referralBenefit }, () => ''));

  const [nameError, setNameError] = useState('');
  const [amountError, setAmountError] = useState('');
  const [monthlyBenefitError, setMonthlyBenefitError] = useState('');
  const [referralBenefitError, setReferralBenefitError] = useState('');
  const [salaryPercError, setSalaryPercError] = useState('');
  const [textFieldsErrors, setTextFieldsErrors] = useState(Array.from({ length: referralBenefit }, () => ''));
  useEffect(() => {
    dispatch(getSettings({ strType: 'settings', type: 'commonList' }));
  }, []);
  const { settingsValues } = useSelector((state) => state?.settings);

  useEffect(() => {
    setSalaryPerc(settingsValues[6]?.intPercentage);
  }, [settingsValues]);
  useEffect(() => {
    setTextFields(Array.from({ length: referralBenefit }, () => ''));
  }, [referralBenefit]);

  useEffect(() => {
    setTextFieldsErrors(Array.from({ length: referralBenefit }, () => ''));
  }, [referralBenefit]);

  const handleTextFieldChange = (index, event) => {
    console.log(event.target.value);
    let newTextFieldValue;
    // if (!event.target.value || typeof event.target.value !== 'number') {
    //   newTextFieldValue = 0;
    // }
    newTextFieldValue = parseFloat(event.target.value);

    setTextFields((prevTextFields) => {
      const updatedTextFields = [...prevTextFields];
      updatedTextFields[index] = newTextFieldValue;
      return updatedTextFields;
    });
  };
  const validateForm = () => {
    let isValid = true;

    if (!name) {
      setNameError('Name is required');
      isValid = false;
    } else {
      setNameError('');
    }

    if (!amount || isNaN(amount)) {
      setAmountError('Amount must be a valid number');
      isValid = false;
    } else {
      setAmountError('');
    }

    if (!monthlyBenefit || isNaN(monthlyBenefit)) {
      setMonthlyBenefitError('Minimum Investment Amount must be a valid number');
      isValid = false;
    } else {
      setMonthlyBenefitError('');
    }
    // if (!salaryPerc || isNaN(salaryPerc)) {
    //   setSalaryPercError('Salary benefit must be a valid number');
    //   isValid = false;
    // } else {
    //   setSalaryPercError('');
    // }

    if (isNaN(referralBenefit) || referralBenefit < 1) {
      setReferralBenefitError('Minimum Direct Referral Count must be a positive integer');
      isValid = false;
    } else {
      setReferralBenefitError('');
    }
    // console.log(textFields);
    // textFields.forEach((value, index) => {
    //   console.log(isNaN(value));
    //   if (isNaN(value) || value === '') {
    //     console.log('working');
    //     setTextFieldsErrors((prevErrors) => {
    //       const updatedErrors = [...prevErrors];
    //       updatedErrors[index] = 'Value must be a number';
    //       return updatedErrors;
    //     });
    //     isValid = false;
    //   } else {
    //     setTextFieldsErrors((prevErrors) => {
    //       const updatedErrors = [...prevErrors];
    //       updatedErrors[index] = '';
    //       return updatedErrors;
    //     });
    //   }
    // });

    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      // Your form is valid, you can proceed with submission or other actions
      // For example, you can send the data to your server here
      const data = {
        strName: name,
        intAmount: amount,
        intMinDirectReferral: referralBenefit,
        intMinInvestment: monthlyBenefit,
        intAimAmtPerc: salaryPerc,
        intAimAmount: (amount / salaryPerc) * 100
        // arrReferalAmtPerc: textFields
      };
      await dispatch(createTeampool(data));
      onClose();
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2} p={2}>
        <Grid item xs={12} md={12}>
          <TextField label="Name" onChange={(e) => setName(e.target.value)} fullWidth error={!!nameError} helperText={nameError} />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            label="Salary Amount"
            onChange={(e) => setAmount(parseInt(e.target.value))}
            fullWidth
            error={!!amountError}
            helperText={amountError}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            label="Minimum Investment Amount"
            type="number"
            // inputProps={{ step: 'any' }}
            onChange={(e) => setMonthlyBenefit(parseFloat(e.target.value))}
            fullWidth
            error={!!monthlyBenefitError}
            helperText={monthlyBenefitError}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            type="number"
            label="Minimum Direct Referral Count"
            onChange={(e) => setReferralBenefit(parseInt(e.target.value))}
            fullWidth
            error={!!referralBenefitError}
            helperText={referralBenefitError}
          />
        </Grid>
        {/* <Grid item xs={12} md={12}>
          <TextField
            type="number"
            inputProps={{ step: 'any' }}
            label="Percentage"
            onChange={(e) => setSalaryPerc(parseFloat(e.target.value))}
            fullWidth
            error={!!salaryPercError}
            helperText={salaryPercError}
          />
        </Grid> */}
        {/* <Grid item xs={12} md={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Typography>Level percentage :</Typography>
            </Grid>
            {textFields.map((textFieldValue, index) => (
              <Grid item xs={12} md={12} key={index}>
                <TextField
                  label={`Level ${index + 1}`}
                  inputProps={{ step: 'any' }}
                  variant="outlined"
                  type="number"
                  fullWidth
                  value={textFieldValue}
                  onChange={(event) => handleTextFieldChange(index, event)}
                  error={!!textFieldsErrors[index]}
                  helperText={textFieldsErrors[index]}
                />
              </Grid>
            ))}
          </Grid>
        </Grid> */}
        <Grid item xs={12} md={12}>
          <Button variant="contained" type="submit">
            Submit
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default NewTeamPoolForm;
