import { useEffect, useState } from 'react';

// material-ui
import { Grid } from '@mui/material';

// project imports
// import EarningCard from './EarningCard';
import PopularCard from './PopularCard';
// import TotalOrderLineChartCard from './TotalOrderLineChartCard';
import TotalIncomeDarkCard from './TotalIncomeDarkCard';
import TotalIncomeLightCard from './TotalIncomeLightCard';
import TotalGrowthBarChart from './TotalGrowthBarChart';
import { gridSpacing } from 'store/constant';
import DepositCard from './DepositCard';
import DailyProfitCard from './DailyProfitCard';
import MileStoneCard from './MileStoneCard';
import { useDispatch, useSelector } from 'react-redux';
import ReferralTotal from './ReferralTotal';
import SalaryProfitCard from './SalaryProfitCard';
import MileStoneDonutChart from './MileStoneDonutChart';
import EarningCard from './EarningCard';
import TotalOrderLineChartCard from './TotalOrderLineChartCard';
import AvailAmount from './AvailAmount';
import WithdrawalAmount from './WithdrawalAmount';
import { useParams } from 'react-router-dom';
import { getWalletDetails } from 'store/wallet/actions';
import { getAllTransaction } from 'store/auth/actions';
import api from 'utils/api';
import InfinitySalaryAmt from './InfinitySalaryAmt';
import TeampoolBar from './TeampoolBar';
import IndirectReferralsAmount from './IndirectReferralsAmout';

// ==============================|| DEFAULT DASHBOARD ||============================== //

const Dashboard = () => {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(true);
  const [userRefCode, setUserRefCode] = useState('');
  const [infiitySalaryAmt, setInfinitySalaryAmt] = useState(0);
  const [indirectReferralInvestment, setIndirectReferralInvestment] = useState(0);
  // const { user } = useSelector((state) => state?.auth);
  const params = useParams();
  const userId = params?.userId;
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userData = await api.post('/get_user_by_id', { strUserId: userId });
        setUserRefCode(userData?.data?.strRefCode);
      } catch (error) {
        console.log(error);
      }
    };
    fetchUserData();
  }, [userId]);
  useEffect(() => {
    setLoading(false);
  }, []);
  useEffect(() => {
    dispatch(getWalletDetails({ _id: userId }));
    dispatch(getAllTransaction({ strUserId: userId }));
  }, []);
  const { transactionList } = useSelector((state) => state?.auth);
  const salaryReferalIncomeArray = transactionList?.filter((item) => item.strType === 'SALARY REFERAL');
  const salaryReferalIncomeTotal = salaryReferalIncomeArray?.reduce((total, item) => total + item.intAmt, 0);
  const { walletDetails } = useSelector((state) => state?.wallet);
  const referalTotal = walletDetails?.arrWalletsTotals?.find((walletsTotal) => walletsTotal.strIncomeType === 'REFERAL');
  const dailyProfit = walletDetails?.arrWalletsTotals?.find((walletsTotal) => walletsTotal.strIncomeType === 'PROFIT');
  const withdrawalAmount = walletDetails?.arrWalletsTotals?.find((walletsTotal) => walletsTotal.strIncomeType === 'RETURNS');
  const salaryCredits = walletDetails?.arrWalletsTotals?.find((walletsTotal) => walletsTotal.strIncomeType === 'SALARY_CREDIT');
  const salaryCreditsInfinity = walletDetails?.arrWalletsTotals?.find(
    (walletsTotal) => walletsTotal.strIncomeType === 'INFINITY_SALARY_CREDIT'
  );
  const primeProfit = walletDetails?.arrWalletsTotals?.find((walletsTotal) => walletsTotal.strIncomeType === 'PRIME_PROFIT');
  const topupWithdrawalAmount = walletDetails?.arrWalletsTotals?.find((walletsTotal) => walletsTotal.strIncomeType === 'TOPUP_WITHDRAW');
  const mileStonereached =
    (referalTotal?.intAmt ? referalTotal?.intAmt : 0) +
    (walletDetails?.arrCapitals[0]?.intAmt ? walletDetails?.arrCapitals[0]?.intAmt : 0) +
    (salaryReferalIncomeTotal ? salaryReferalIncomeTotal : 0);
  return (
    <Grid container spacing={gridSpacing}>
      <Grid item xs={12}>
        <Grid container spacing={gridSpacing}>
          <Grid item lg={4} md={6} sm={6} xs={12}>
            <EarningCard amount={walletDetails?.arrCapitals[0]?.intAmt ? walletDetails?.arrCapitals[0]?.intAmt : 0} isLoading={isLoading} />
          </Grid>
          <Grid item lg={4} md={6} sm={6} xs={12}>
            <TotalOrderLineChartCard text={'Gross Profit'} amount={dailyProfit?.intAmt ? dailyProfit?.intAmt : 0} isLoading={isLoading} />
          </Grid>
          <Grid item lg={4} md={6} sm={6} xs={12}>
            <TotalOrderLineChartCard text={'Prime Reward'} amount={primeProfit?.intAmt ? primeProfit?.intAmt : 0} isLoading={isLoading} />
          </Grid>
          <Grid item lg={4} md={12} sm={12} xs={12}>
            <Grid container spacing={gridSpacing}>
              <Grid item sm={6} xs={12} md={6} lg={12}>
                <TotalIncomeDarkCard amount={referalTotal?.intAmt ? referalTotal?.intAmt : 0} isLoading={isLoading} />
              </Grid>
              <Grid item sm={6} xs={12} md={6} lg={12}>
                <WithdrawalAmount amount={withdrawalAmount?.intAmt ? withdrawalAmount?.intAmt : 0} isLoading={isLoading} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={gridSpacing}>
          <Grid item lg={4} md={4} sm={4} xs={12}>
            <AvailAmount
              amount={
                // (walletDetails?.arrCapitals[0]?.intAmt ? walletDetails?.arrCapitals[0]?.intAmt : 0) +
                (referalTotal?.intAmt ? referalTotal?.intAmt : 0) +
                (dailyProfit?.intAmt ? dailyProfit?.intAmt : 0) +
                (withdrawalAmount?.intAmt ? withdrawalAmount?.intAmt : 0) +
                (salaryCredits?.intAmt ? salaryCredits?.intAmt : 0) +
                (primeProfit?.intAmt ? primeProfit?.intAmt : 0) +
                (salaryCreditsInfinity?.intAmt ? salaryCreditsInfinity?.intAmt : 0) +
                (topupWithdrawalAmount?.intAmt ? topupWithdrawalAmount?.intAmt : 0)
              }
              isLoading={isLoading}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={12}>
            <MileStoneDonutChart userId={userId} />
          </Grid>
          {/* <Grid item lg={4} md={4} sm={4} xs={12}>
            <MileStoneDonutChart mileStonereached={mileStonereached} />
          </Grid> */}
          <Grid item lg={4} md={4} sm={4} xs={12}>
            <TotalIncomeLightCard
              isLoading={isLoading}
              investmentAmount={walletDetails?.arrCapitals[0]?.intAmt ? walletDetails?.arrCapitals[0]?.intAmt : 0}
              userRefCode={userRefCode}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={4} md={6} sm={6} xs={12}>
        <TotalOrderLineChartCard
          text={'Total Salary Credited'}
          amount={salaryCredits?.intAmt ? salaryCredits?.intAmt : 0}
          isLoading={isLoading}
        />
      </Grid>
      <Grid item lg={4} md={6} sm={6} xs={12}>
        <TeampoolBar indirectReferralInvestment={indirectReferralInvestment} setInfinitySalaryAmt={setInfinitySalaryAmt} />
      </Grid>
      <Grid item lg={4} md={6} sm={6} xs={12}>
        <IndirectReferralsAmount
          userRefCode={userRefCode}
          indirectReferralInvestment={indirectReferralInvestment}
          setIndirectReferralInvestment={setIndirectReferralInvestment}
          isLoading={isLoading}
        />
      </Grid>
      <Grid item lg={4} md={6} sm={6} xs={12}>
        <InfinitySalaryAmt infiitySalaryAmt={infiitySalaryAmt} />
      </Grid>
      <Grid item lg={4} md={6} sm={6} xs={12}>
        <TotalOrderLineChartCard
          text={'Total Infinity Salary Credited'}
          amount={salaryCreditsInfinity?.intAmt ? salaryCreditsInfinity?.intAmt : 0}
          isLoading={isLoading}
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={gridSpacing}>
          <Grid item xs={12} md={8}>
            <TotalGrowthBarChart isLoading={isLoading} />
          </Grid>
          <Grid item xs={12} md={4}>
            <PopularCard
              walletArray={walletDetails?.arrList}
              walletAmount={walletDetails?.arrTotals[0]?.intAmt}
              isDashboard={true}
              isLoading={isLoading}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Dashboard;
