import React from 'react';

const HomePage = () => {
  return (
    <div>
      <h2>HomePage</h2>
      <a href="/login">Login</a>
    </div>
  );
};

export default HomePage;
