import { Button, Card, Divider, Grid, TextField, Typography } from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik/dist';
import React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createFaq, deleteFaq, getFaq } from 'store/faq/actions';
import * as Yup from 'yup';

const FAQ = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getFaq());
  }, []);
  const validationSchema = Yup.object().shape({
    strQstn: Yup.string().required('Question is required'),
    strAnswer: Yup.string().required('Answer is required')
  });
  const formik = useFormik({
    initialValues: {
      strQstn: '',
      strAnswer: ''
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      // console.log(values);
      // values.strPercentageRane = values.strPercentageRane + '%';
      await dispatch(createFaq(values));
      resetForm();
    }
  });
  const { handleChange, handleSubmit, touched, values, errors } = formik;
  const { faqList } = useSelector((state) => state?.faq);
  const handleClickDelete = (id) => {
    const data = {
      _id: id,
      strType: 'faq',
      type: 'commonDelete'
    };
    dispatch(deleteFaq(data));
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <Card sx={{ padding: '16px' }}>
          <Typography variant="h3">FAQ</Typography>
        </Card>
      </Grid>
      <Grid item xs={12} md={12}>
        <Card sx={{ p: '16px' }}>
          <FormikProvider value={formik}>
            <Form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <TextField
                    value={values.strQstn}
                    label="New Question"
                    name="strQstn"
                    fullWidth
                    onChange={handleChange}
                    style={{ marginBottom: '16px' }}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField
                    value={values.strAnswer}
                    label="Answer"
                    multiline
                    rows={3}
                    name="strAnswer"
                    fullWidth
                    onChange={handleChange}
                    style={{ marginBottom: '16px' }}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <Button variant="contained" type="submit">
                    Add
                  </Button>
                </Grid>
              </Grid>
            </Form>
          </FormikProvider>
        </Card>
      </Grid>
      <Grid item xs={12} md={12}>
        <Card sx={{ p: '16px' }}>
          <Grid container spacing={2}>
            {faqList?.map((faq) => (
              <Grid item xs={12} md={12} key={faq._id}>
                <Typography>{faq?.strQstn}</Typography>
                <Typography mt={2}>{faq?.strAnswer}</Typography>
                <Button variant="contained" color="error" onClick={() => handleClickDelete(faq?._id)}>
                  Delete
                </Button>
                <Divider sx={{ mt: 1 }} />
              </Grid>
            ))}
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};

export default FAQ;
