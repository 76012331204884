import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { LOGIN_SUCCESS } from 'store/auth/constants';
import { useEffect } from 'react';
import { handleToken } from 'utils/jwt';

const GuestRoute = ({ children }) => {
  const { pathname } = useLocation();
  console.log(pathname);
  const dispatch = useDispatch();
  const { isLoggedIn } = useSelector((state) => state?.auth);
  // useEffect(() => {
  //   const isAuthenticate = localStorage.getItem('isAuthenticate');
  //   const userDetail = JSON.parse(localStorage.getItem('user'));
  //   if (isAuthenticate && userDetail) {
  //     handleToken(userDetail?.strToken);
  //     dispatch({
  //       type: LOGIN_SUCCESS,
  //       payload: {
  //         loginSuccess: true,
  //         userDetails: userDetail
  //       }
  //     });
  //   }
  // }, [isLoggedIn]);
  if (isLoggedIn) {
    return <Navigate to="/admin" />;
  }
  return <>{children}</>;
};

GuestRoute.propTypes = {
  children: PropTypes.node
};

export default GuestRoute;
