import { GET_CHAT_HISTORY, GET_MESSAGE_HISTORY } from './constants';

const initialState = {
  chatHistory: [],
  messageHistory: []
};

export default function chat(state = initialState, { type, payload }) {
  switch (type) {
    case GET_CHAT_HISTORY:
      return {
        ...state,
        chatHistory: payload
      };
    case GET_MESSAGE_HISTORY:
      return {
        ...state,
        messageHistory: payload
      };
    default:
      return state;
  }
}
