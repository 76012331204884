import { GET_PAYMENT_QRS } from './constants';

const initialState = {
  paymentQRlists: []
};

export default function paymentQR(state = initialState, { type, payload }) {
  switch (type) {
    case GET_PAYMENT_QRS:
      return {
        ...state,
        paymentQRlists: payload
      };
    default:
      return state;
  }
}
