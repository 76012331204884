import { Button, Card, CardContent, Grid, Typography } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import CustomDrawer from 'ui-component/drawer/CustomDrawer';
import NewMilestoneForm from './NewMilestoneForm';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { deleteMilestone, getMileStones } from 'store/milestone/actions';
import mileStone from 'store/milestone/reducer';
import { deleteQR } from 'store/paymentQR/actions';
import EditMilestone from './EditMilestone';

const Milestone = () => {
  const dispatch = useDispatch();
  const [openEdit, setOpenEdit] = useState(false);
  const [editValues, setEditValues] = useState(null);
  const handleOpenEdit = () => {
    setOpenEdit(true);
  };
  const handleCloseEdit = () => {
    setOpenEdit(false);
  };
  const [openDrawer, setOpenDrawer] = useState(false);
  const handleOpen = () => {
    setOpenDrawer(true);
  };
  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };
  const handleClickNew = () => {
    handleOpen();
  };
  useEffect(() => {
    dispatch(getMileStones());
  }, []);
  const { mileStoneList } = useSelector((state) => state?.mileStone);
  console.log(mileStoneList);
  const handleClickDelete = (id) => {
    const data = {
      _id: id,
      strType: 'milestone',
      type: 'commonDelete'
    };
    dispatch(deleteMilestone(data));
  };
  const handleClickEdit = (values) => {
    setEditValues(values);
    handleOpenEdit();
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <Card sx={{ p: '16px' }}>
          <Typography variant="h3">Milestone</Typography>
        </Card>
      </Grid>
      <Grid item xs={12} md={12}>
        <Button variant="contained" onClick={handleClickNew}>
          {' '}
          + New Milestone
        </Button>
      </Grid>
      <Grid item xs={12} md={12}>
        <Grid container>
          {mileStoneList?.length > 0 &&
            mileStoneList?.map((data) => (
              <Grid item xs={12} md={4} key={data?._id}>
                <Card variant="outlined">
                  <CardContent>
                    <Typography variant="h6" component="div">
                      {data.strName}
                    </Typography>
                    <Typography color="textSecondary">Amount: {data.intAmount}</Typography>
                    <Typography color="textSecondary">Minimum Invesment Amount: {data.intMinInvestment}</Typography>
                    <Typography color="textSecondary">Minimum Direct Referral Count: {data.intMinDirectReferral}</Typography>
                    {/* <Typography color="textSecondary">Referral Amounts:</Typography> */}
                    {/* <ul>
                      {data?.arrReferalAmtPerc?.map((amount, index) => (
                        <li key={index}>{amount}%</li>
                      ))}
                    </ul> */}
                    <Button variant="contained" color="success" onClick={() => handleClickEdit(data)}>
                      Edit
                    </Button>
                    <Button variant="contained" color="error" onClick={() => handleClickDelete(data?._id)}>
                      Delete
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
            ))}
        </Grid>
      </Grid>
      <CustomDrawer open={openDrawer} onClose={handleCloseDrawer} title={'Add Milestone'}>
        <NewMilestoneForm onClose={handleCloseDrawer} />
      </CustomDrawer>
      <CustomDrawer open={openEdit} onClose={handleCloseEdit} title={'Edit Milestone'}>
        <EditMilestone milestoneDetails={editValues} onClose={handleCloseEdit} />
      </CustomDrawer>
    </Grid>
  );
};

export default Milestone;
