import { Card, Grid, IconButton, Typography } from '@mui/material';
import React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllTransaction } from 'store/auth/actions';
import { getUsersList } from 'store/users/actions';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CustomDrawer from 'ui-component/drawer/CustomDrawer';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SearchComponent from 'ui-component/SearchComponent';
import CustomPagination from 'ui-component/CustomPagination';

const UserDashboardList = () => {
  const navigate = useNavigate();
  const [fullName, setFullName] = useState('');
  const [userId, setUserId] = useState('');
  const [filter, setFilter] = useState('a');
  const [open, setOpen] = useState(false);
  const [strSearch, setStrSearch] = useState('');
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(30);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const dispatch = useDispatch();
  //   useEffect(() => {
  //     dispatch(getAllTransaction());
  //   }, []);
  useEffect(() => {
    dispatch(getUsersList({ chrStatus: 'N', page: page, limit: limit, strSearch: strSearch }));
  }, [page, limit, strSearch]);
  const { usersList, usersCount } = useSelector((state) => state?.users);
  console.log(usersList);
  const handleClickUser = async (id, name) => {
    // await dispatch(getAllTransaction({ strUserId: id }));
    setUserId(id);
    setFullName(name);
    navigate(`/detaileduserdashboard/${id}`);
    // handleOpen();
  };
  // const { transactionList } = useSelector((state) => state?.auth);
  // console.log(transactionList);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <Card sx={{ padding: '16px' }}>
          <Typography variant="h3">Users List</Typography>
        </Card>
      </Grid>
      <Grid item xs={12} md={12}>
        <SearchComponent searchValue={strSearch} setSearchValue={setStrSearch} />
      </Grid>
      <Grid item xs={12} md={12}>
        <Grid container>
          <Grid item md={6} xs={12}>
            <Grid container spacing={2}>
              {usersList?.map((user, i) => (
                <Grid item xs={12} key={i}>
                  <Card onClick={() => handleClickUser(user?._id, user?.strFullName)}>
                    <Grid container p={2}>
                      <Grid item xs={10}>
                        <Typography>Name : {user?.strFullName}</Typography>
                        <Typography>Username : {user?.strName}</Typography>
                        <Typography>Email : {user?.strEmail}</Typography>
                      </Grid>
                      <Grid item xs={2} display={'flex'} alignItems={'center'}>
                        <IconButton>
                          <ArrowForwardIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item md={6} xs={12}></Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={12}>
        <CustomPagination count={usersCount} page={page} rowsPerPage={limit} setRowsPerPage={setLimit} onPageChange={setPage} />
      </Grid>
    </Grid>
  );
};

export default UserDashboardList;
