import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import PrivateRoute from 'guards/PrivateRoute';
import UserProfile from 'views/pages/profile/UserProfile';
import Plans from 'views/pages/packages/Plans';
import AccountSettings from 'views/pages/accountsettings/AccountSettings';
import Investments from 'views/pages/investments/Investments';
import DetailedInvestment from 'views/pages/investments/DetailedInvestment';
import UsersList from 'views/pages/users/UsersList';
import UserDetailed from 'views/pages/users/UserDetailed';
import Withdrawals from 'views/pages/withdraw/Withdrawals';
import DetailedWithdrawals from 'views/pages/withdraw/DetailedWithdrawals';
import Chat from 'views/pages/chat/Chat';
import ChatMain from 'views/pages/chat/ChatMain';
import PickNewChat from 'views/pages/chat/PickNewChat';
import ChatUI from 'views/pages/chat/ChatUI';
import Milestone from 'views/pages/milestone/Milestone';
import FAQ from 'views/pages/faq/FAQ';
import Transactions from 'views/pages/transactions/Transactions';
import UserDashboardList from 'views/pages/userdashboard/UserDashboardList';
import DetailedUserDashboard from 'views/pages/userdashboard/DetailedUserDashboard';
import KYCUsersList from 'views/pages/KYCUsers/KYCUsersList';
import KYCUserDetailed from 'views/pages/KYCUsers/KYCUserDetailed';
import Test from 'views/pages/test/Test';
import AchievedMilestonesList from 'views/achievedMilestones/AchievedMilestonesList';
import SalaryLogs from 'views/salaryLogs/SalaryLogs';
import TeamPoolMain from 'views/pages/teampool/TeamPoolMain';
import TeampoolAchievedUsers from 'views/pages/teampool/TeampoolAchievedUsers';
import TeamPoolSalaryLogs from 'views/pages/teampool/TeamPoolSalaryLogs';
import TopupInvestmentLogs from 'views/pages/investments/TopupInvestmentLogs';
import PlusInvestments from 'views/pages/plusinvestments/PlusInvestments';
import DetailedPlusInvestment from 'views/pages/plusinvestments/DetailedPlusInvestment';
import ZeroInvestmentList from 'views/pages/investments/ZeroInvestmentList';
import UsersTotalInvestmentList from 'views/pages/investments/UsersTotalInvestmentList';
import ManualWithdrawals from 'views/pages/withdraw/ManualWithdrawals';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: (
    <PrivateRoute>
      <MainLayout />
    </PrivateRoute>
  ),
  children: [
    {
      path: '/admin',
      element: (
        <PrivateRoute>
          <DashboardDefault />
        </PrivateRoute>
      )
    },
    {
      path: 'dashboard',
      element: <DashboardDefault />
      // children: [
      //   {
      //     path: '',

      //   }
      // ]
    },
    {
      path: 'utils',
      children: [
        {
          path: 'util-typography',
          element: <UtilsTypography />
        }
      ]
    },
    {
      path: 'utils',
      children: [
        {
          path: 'util-color',
          element: <UtilsColor />
        }
      ]
    },
    {
      path: 'utils',
      children: [
        {
          path: 'util-shadow',
          element: <UtilsShadow />
        }
      ]
    },
    {
      path: 'icons',
      children: [
        {
          path: 'tabler-icons',
          element: <UtilsTablerIcons />
        }
      ]
    },
    {
      path: 'icons',
      children: [
        {
          path: 'material-icons',
          element: <UtilsMaterialIcons />
        }
      ]
    },
    {
      path: 'sample-page',
      element: <SamplePage />
    },
    { path: 'profile', element: <UserProfile /> },
    {
      path: 'account',
      element: <AccountSettings />
    },
    {
      path: 'plans',
      element: <Plans />
    },
    {
      path: 'investment',
      element: <Investments />
    },
    {
      path: 'plusinvestment',
      element: <PlusInvestments />
    },
    {
      path: 'investment/detailed/:investmentId',
      element: <DetailedInvestment />
    },
    {
      path: 'plusinvestment/detailed/:investmentId',
      element: <DetailedPlusInvestment />
    },
    {
      path: 'users',
      element: <UsersList />
    },
    {
      path: 'users/userdetailed/:userId',
      element: <UserDetailed />
    },
    {
      path: 'withdraw',
      element: <Withdrawals />
    },
    {
      path: 'withdraw/detailedwithdrawal/:withdrawalId',
      element: <DetailedWithdrawals />
    },
    {
      path: '/withdrawals/manualwithdrawal',
      element: <ManualWithdrawals />
    },
    {
      path: 'chat',
      element: <ChatMain />
    },
    {
      path: 'chat/newchat',
      element: <PickNewChat />
    },
    {
      path: 'chat/chatwindow/:chatId',
      element: <ChatUI />
    },
    {
      path: 'milestone',
      element: <Milestone />
    },
    {
      path: 'faq',
      element: <FAQ />
    },
    {
      path: 'transactions',
      element: <Transactions />
    },
    {
      path: 'dashboarduserslist',
      element: <UserDashboardList />
    },
    {
      path: 'detaileduserdashboard/:userId',
      element: <DetailedUserDashboard />
    },
    {
      path: 'kycstatus',
      element: <KYCUsersList />
    },
    {
      path: 'kycstatus/userdetailed/:userId',
      element: <KYCUserDetailed />
    },
    {
      path: 'test',
      element: <Test />
    },
    {
      path: 'achievedmilestones',
      element: <AchievedMilestonesList />
    },
    {
      path: 'salarylogs',
      element: <SalaryLogs />
    },
    {
      path: 'infinity',
      element: <TeamPoolMain />
    },
    {
      path: 'infinity/achievedusers',
      element: <TeampoolAchievedUsers />
    },
    {
      path: 'infinity/salarylogs',
      element: <TeamPoolSalaryLogs />
    },
    {
      path: 'investment/topuplogs',
      element: <TopupInvestmentLogs />
    },
    {
      path: 'investment/zeroinvestmentusers',
      element: <ZeroInvestmentList />
    },
    {
      path: 'investment/totalinvestmentusers',
      element: <UsersTotalInvestmentList />
    }
  ]
};

export default MainRoutes;
