export const GET_INVESTMENTS = 'GET_INVESTMENTS';
export const CHANGE_CHRSTATUSINV = 'CHANGE_CHRSTATUSINV';
export const CHANGE_TOPUP_CHRSTATUSINV = 'CHANGE_TOPUP_CHRSTATUSINV';
export const GET_TOPUP_LOGS = 'GET_TOPUP_LOGS';
export const CHANGE_INVESTMENTS_LIST_PAGE = 'CHANGE_INVESTMENTS_LIST_PAGE';
export const CHANGE__USERS_TOTAL_INVESTMENTS_LIST_PAGE = 'CHANGE__USERS_TOTAL_INVESTMENTS_LIST_PAGE';
export const GET_PLUS_INVESTMENTS = 'GET_PLUS_INVESTMENTS';
export const CHANGE_PLUS_STATUS = 'CHANGE_PLUS_STATUS';
export const CHANGE_PLUS_PAGE = 'CHANGE_PLUS_PAGE';
export const GET_ZERO_INVESTMENTS = 'GET_ZERO_INVESTMENTS';
export const GET_USERS_TOTAL_INVESTMENTS = 'GET_USERS_TOTAL_INVESTMENTS';
