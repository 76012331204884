import { Avatar, Button, Card, Grid, Typography } from '@mui/material';
import React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getChatHistory } from 'store/chat/actions';
import io from 'socket.io-client';
import { useState } from 'react';

const ChatMain = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [socket, setSocket] = useState(null);
  const { user } = useSelector((state) => state?.auth);
  useEffect(() => {
    dispatch(getChatHistory());
  }, []);
  const { chatHistory } = useSelector((state) => state?.chat);
  const handleClickNew = () => {
    navigate('/chat/newchat');
  };
  useEffect(() => {
    if (!user.strToken) {
      return;
    }
    // Initialize the WebSocket connection
    // const socketConnection = io('http://localhost:4010', {
    //   query: {
    //     token: user.strToken
    //   }
    // });
    // const socketConnection = io('http://65.0.170.77:4010', {
    //   query: {
    //     token: user.strToken
    //   }
    // });
    const socketConnection = io('https://chat.royalprimefx.com', {
      query: {
        token: user.strToken
      }
    });

    setSocket(socketConnection);

    return () => {
      // Disconnect the WebSocket when the component unmounts
      socketConnection.disconnect();
    };
  }, [user]);
  useEffect(() => {
    if (!socket) return;

    // Listen for the 'connect' event
    socket.on('connect', () => {
      console.log('Connected to the Socket.io server');
    });
    // socket.emit('join_room', {
    //   strChatId: '651411223ea2e0e9983ed176',
    //   strType: 'private'
    // });
    // Listen for incoming messages
    socket.on('get_message', (message) => {
      //   setMessages((prevMessages) => [...prevMessages, message]);
      console.log(message);
    });

    // Listen for chat history
    socket.on('chat_history', (history) => {
      //   setMessages(history);
      console.log(history);
    });

    // Listen for 'disconnect' event
    socket.on('disconnect', () => {
      console.log('Disconnected from the Socket.io server');
    });
  }, [socket]);
  const handleClickChat = (id) => {
    navigate(`/chat/chatwindow/${id}`);
  };
  console.log('chatHistory', chatHistory);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <Card sx={{ padding: '16px' }}>
          <Typography variant="h3">Chat</Typography>
        </Card>
      </Grid>
      <Grid item xs={12} md={12} display={'flex'} justifyContent={'right'}>
        <Button onClick={handleClickNew} variant="contained">
          {' '}
          + New Chat
        </Button>
      </Grid>
      <Grid item xs={12} md={12}>
        <Grid container>
          <Grid item xs={12} md={6}>
            <Card variant="outlined" sx={{ p: '16px' }}>
              <Grid container spacing={2}>
                {chatHistory?.length > 0 &&
                  chatHistory?.map((history) => (
                    <Grid item xs={12} md={12} key={history?._id}>
                      <Card onClick={() => handleClickChat(history?.strChatId)}>
                        <Grid container justifyContent={'space-between'}>
                          <Grid item>
                            <Grid container justifyContent={'flex-start'}>
                              <Grid item>
                                <Avatar sx={{ height: 60, width: 60 }}>H</Avatar>
                              </Grid>
                              <Grid item sx={{ ml: '10px' }} gap={'10px'}>
                                <Typography variant="h3">{history?.strName?.substring(0, 14)}</Typography>
                                <Typography>{history?.strMessage}</Typography>
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid item display={'flex'} alignItems={'center'}>
                            <Typography>{history?.strCreatedTime?.split('T')[1]?.substring(0, 5)}</Typography>
                          </Grid>
                        </Grid>
                      </Card>
                    </Grid>
                  ))}
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}></Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ChatMain;
