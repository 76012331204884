import api from 'utils/api';
import { GET_TEAMPOOL_LIST } from './constants';

export const getTeamPool = () => async (dispatch) => {
  try {
    const body = {
      strType: 'infinity',
      type: 'commonList'
    };
    const { data } = await api.post('/common_api', body);
    dispatch({ type: GET_TEAMPOOL_LIST, payload: data?.arrList });
  } catch (error) {
    console.log(error);
  }
};
export const createTeampool = (body) => async (dispatch) => {
  const newBody = {
    strType: 'infinity',
    type: 'commonCreate',
    objDocument: body
  };
  try {
    const { data } = await api.post('/common_api', newBody);
    await dispatch(getTeamPool());
  } catch (error) {
    console.log(error);
  }
};
export const deleteTeampool = (body) => async (dispatch) => {
  try {
    const { data } = await api.post('/common_api', body);
    await dispatch(getTeamPool());
  } catch (error) {
    console.log(error);
  }
};
export const updateTeampool = (body) => async (dispatch) => {
  try {
    const { data } = await api.post('/common_api', body);
    await dispatch(getTeamPool());
  } catch (error) {
    console.log(error);
  }
};
